import jsonPointer from 'json-pointer';
import resourceBoundSubsystem from './resource-bound-subsystem.mjs';
import * as shipUtil from './utils.mjs';
import tagLib from './tags.mjs';
import walkSpec from './walk-spec.mjs';
import validate from './validate.mjs';
import Vm from './vm.mjs';

import { ClientProblem } from './vm.mjs';
import { expressionType, shipOrder, shipOrderTemplate } from '../specs.mjs';
import { simulateAdd } from './resource-bound-subsystem.mjs';
import { stripZeroValues } from './model.mjs';
import { ValidationError } from './validate.mjs';

const automation = {
    automationAppendOrder,
    automationFallback,
    automationIf,
    automationInsertOrderAfter,
    automationInsertOrderBefore,
};

const orderTypes = {
    orderClaimJob,
    orderFinishJob,
    orderLoad,
    orderInstall,
    orderTravel,
    orderUnload,
    orderUninstall,
    orderWait,
};

const routines = {
    // Backward compatibility with the old name
    routineCheckDeliveryComplete: routineDoDeliveryFinish,

    routineDeleteJob,
    routineDoDeliveryFinish,
    routineDoDeliveryStart,
};

class OrderSimulationWarning extends Error {
    constructor(msg, details = {}) {
        super(msg);

        this.details = details;
        this.code = 'ORDER_SIMULATION_WARNING';
    }
}

/**
 * Goal here is that effects of a ship's order can be accumulated
 * "hypothetically" without worrying about non-deterministic limits like
 * resource availability or permissions (i.e., it's possible to go into negative
 * counts of resources, exceed available storage, or perform actions for which
 * you do not currently have permission). This both forms the basis of
 * implementing the "real" associated orders (with an additional validation
 * step), and allows us to "symbolically execute" the orders against the ship
 * to provide validation guidance on the client side (e.g., "I don't think you
 * will actually have the cargo space for order #4 when you get to it, but that
 * could change...").
 *
 * Callers should therefore pass in a _copy_ of their model that they can afford
 * to have changed in a way that violates invariants and needs to be rolled
 * back and resulting models should not ever be used as actual game state
 * without first validating them against invariants.
 */
export default function simulateOrder(
    shipId,
    model,
    vm = new Vm(model),
    click,
    {
        accumulatedWarnings = [],
        apply = (m, fn) => fn(m),
        applyAdjustments = true,
        assumeSuccess = true,
    } = {},
) {
    const order = shipUtil.unmessy(model.get(`/${shipId}/orders/0`));

    if (!order) {
        return [true, {}];
    }

    const orderId = model.get(`/${shipId}/orders`)[0].id;

    const [orderType, orderArgs] = shipUtil.dnode(order);

    const orderKey = `order${shipUtil.capitalize(orderType)}`;
    if (!orderTypes[orderKey]) {
        throw new Error(
            'Unknown order type: ' +
                orderType +
                '. (' +
                shipUtil.debugString(order) +
                ')',
        );
    }

    const warn = (msg, details) => {
        details = {
            urgent: true,
            failingOrder: orderType,
            ...details,
        };

        if (assumeSuccess) {
            accumulatedWarnings.push(new OrderSimulationWarning(msg, details));
        } else {
            throw new OrderSimulationWarning(msg, details);
        }
    };

    warn.notify = (...args) =>
        accumulatedWarnings.push(new OrderSimulationWarning(...args));

    let conflictGroup, elapsedClicks;
    try {
        if (
            !maybeDoBefore(
                shipId,
                orderId,
                orderArgs,
                model,
                vm,
                click,
                apply,
                warn,
            )
        ) {
            ({ conflictGroup, elapsedClicks = 1 } =
                orderTypes[orderKey](
                    shipId,
                    orderArgs,
                    model,
                    vm,
                    click,
                    apply,
                    warn,
                    orderId,
                ) ?? {});
        }
    } catch (e) {
        if (
            e.code !== 'ORDER_SIMULATION_WARNING' &&
            !(e instanceof ClientProblem)
        ) {
            shipUtil.unexpectedError(e);
        }

        accumulatedWarnings.push(e);

        // We could only throw if assumeSuccess is false. So fail the
        // order.
        completeOrder(false, shipId, orderArgs, model, vm, click, apply, warn);
    }

    if (applyAdjustments) {
        model.applyAdjustments();
    }

    return [accumulatedWarnings, { conflictGroup, elapsedClicks }];
}

function assert(thing, msg) {
    if (!thing) {
        throw new Error(msg);
    }
}

function evalReplacementMap(model, vm, ctxPath, replacements = []) {
    const replacementMap = new Map();

    for (const { name, value } of replacements) {
        const evaledValue = vm.evaluateSync(value, Vm.context(ctxPath));
        const valueType = expressionType(value);

        if (!valueType) {
            throw new ClientProblem(
                'Expression for replacement ' +
                    name +
                    ' is invalid as a replacement. Unknown type.',
            );
        }

        let wrappedValue;

        switch (valueType) {
            case 'cargoManifest': {
                wrappedValue = shipUtil.buildDNode('literalCargo', {
                    cargo: evaledValue,
                });
                break;
            }
            case 'job': {
                wrappedValue = shipUtil.buildDNode('literalJob', {
                    job: evaledValue,
                });
                break;
            }
            case 'station': {
                wrappedValue = shipUtil.buildDNode('literalStation', {
                    station: evaledValue,
                });
                break;
            }
            default: {
                throw new ClientProblem(
                    'Expression for replacement ' +
                        name +
                        ' is invalid as a replacement. Type ' +
                        valueType +
                        ' is not permitted.',
                );
            }
        }

        replacementMap.set(name, wrappedValue);
    }

    return replacementMap;
}

function automationAppendOrder(
    model,
    click,
    apply,
    vm,
    ctxPath,
    { order, replacements, skipDefaults },
    warn,
) {
    const pathParts = jsonPointer.parse(ctxPath);
    const [shipId] = pathParts;
    const arrayPath = jsonPointer.compile([shipId, 'orders']);

    assert(shipId.startsWith('ship_'), shipId);

    const instantiatedOrder = instantiateOrder(
        model,
        shipId,
        ctxPath,
        shipOrderTemplate,
        order,
        evalReplacementMap(model, vm, ctxPath, replacements),
        skipDefaults,
        vm,
    );

    validateOrder(instantiatedOrder, warn);
    apply(model, (jail) => jail.push(arrayPath, instantiatedOrder));
}

function automationFallback(
    model,
    click,
    apply,
    vm,
    ctxPath,
    { try: tryAutomations, fallback },
    warn,
) {
    try {
        model.atomic((copyModel) => {
            const copyVm = vm.withSharedResources(copyModel);
            for (const a of tryAutomations) {
                doAutomation(copyModel, click, apply, copyVm, ctxPath, a, warn);
            }
        });
    } catch (e) {
        if (
            e.code !== 'ORDER_SIMULATION_WARNING' &&
            !(e instanceof ClientProblem)
        ) {
            shipUtil.unexpectedError(e);
        }

        for (const a of fallback ?? []) {
            doAutomation(model, click, apply, vm, ctxPath, a, warn);
        }
    }
}

function automationIf(
    model,
    click,
    apply,
    vm,
    ctxPath,
    { condition, action, otherwise },
    warn,
) {
    const as =
        (vm.evaluateSync(condition ?? true, Vm.context(ctxPath))
            ? action
            : otherwise) ?? [];

    for (const a of as) {
        doAutomation(model, click, apply, vm, ctxPath, a, warn);
    }
}

function automationInsertOrderAfter(
    model,
    click,
    apply,
    vm,
    ctxPath,
    { order, replacements, skipDefaults },
    warn,
) {
    const [shipId] = jsonPointer.parse(ctxPath);
    assert(shipId.startsWith('ship_'), shipId);

    const instantiatedOrder = instantiateOrder(
        model,
        shipId,
        ctxPath,
        shipOrderTemplate,
        order,
        evalReplacementMap(model, vm, ctxPath, replacements),
        skipDefaults,
        vm,
    );

    validateOrder(instantiatedOrder, warn);
    apply(model, (jail) => jail.add(ctxPath, instantiatedOrder, 1));
}

function automationInsertOrderBefore(
    model,
    click,
    apply,
    vm,
    ctxPath,
    { order, replacements, skipDefaults },
    warn,
) {
    const [shipId] = jsonPointer.parse(ctxPath);
    assert(shipId.startsWith('ship_'), shipId);

    const instantiatedOrder = instantiateOrder(
        model,
        shipId,
        ctxPath,
        shipOrderTemplate,
        order,
        evalReplacementMap(model, vm, ctxPath, replacements),
        skipDefaults,
        vm,
    );

    validateOrder(instantiatedOrder, warn);
    apply(model, (jail) => jail.add(ctxPath, instantiatedOrder));
}

function completeOrder(
    success,
    shipId,
    orderValue,
    model,
    vm,
    click,
    apply,
    warn,
) {
    const ship = model.handle(`/${shipId}`);
    const automations = success
        ? orderValue.triggers?.onSuccess ?? []
        : orderValue.triggers?.onFailure ?? [];

    // We need to leave the current order in place, since it is the context for
    // any automations. But the automations may change the order queue, so make
    // a note of its id so that we can both use it as the automation context and
    // also have a handle to delete the order afterwards.
    const curTopId = model.get(`/${shipId}/orders`)[0].id;

    for (const a of automations) {
        doAutomation(
            model,
            click,
            apply,
            vm,
            `/${shipId}/orders/${curTopId}`,
            a,
            warn,
        );
    }

    model.pop(`/${shipId}/orders`, curTopId);
    ship.set(`/noMoreBefore`, undefined);

    // Take this opportunity to clean up any unused order notes.
    const orderIds = model.get(`/${shipId}/orders`, []).map(({ id }) => id);
    for (const noteId of Object.keys(model.get(`/${shipId}/orderNotes`, {}))) {
        if (!orderIds.includes(noteId)) {
            model.set(`/${shipId}/orderNotes/${noteId}`, null);
        }
    }
}

export function doAutomation(model, click, apply, vm, ctxPath, a, warn) {
    const [type, args] = shipUtil.dnode(a);
    const automationKey = `automation${shipUtil.capitalize(type)}`;

    if (!automation[automationKey]) {
        throw new Error('No such automation. ' + shipUtil.debugString(a));
    }

    automation[automationKey](model, click, apply, vm, ctxPath, args, warn);
}

function maybeDoBefore(
    shipId,
    orderId,
    orderArgs,
    model,
    vm,
    click,
    apply,
    warn,
) {
    if (model.get(`/${shipId}/noMoreBefore`) === orderId) {
        return false;
    }

    model.set(`/${shipId}/noMoreBefore`, undefined);

    if (!orderArgs?.triggers?.before) {
        return false;
    }

    for (const a of orderArgs.triggers.before) {
        doAutomation(model, click, apply, vm, `/${shipId}/orders/0`, a, warn);
    }

    model.set(`/${shipId}/noMoreBefore`, orderId);

    return true;
}

function routineDoDeliveryFinish(model, click, args, warn) {
    const job = model.handle(`/${args.this}`);
    const assigneeId = job.rootNodeId('/assignee');

    if (args.ship !== assigneeId) {
        warn(
            `Ship "${shipUtil.name(model, args.ship)}" is not assigned to ` +
                `specified job.`,
        );
    }

    const shipOwnerId = job.rootNodeId('/assignee/owner');

    for (const [what, count] of shipUtil.unmessy(job.get('/cargo')) ?? []) {
        const inBay = job.getRefArrayElement(
            `/to/corps/${shipOwnerId}/storage`,
            what,
            0,
        );

        if (inBay < count) {
            warn(
                `Insufficient ${shipUtil.name(model, what)} at ` +
                    `${shipUtil.name(model, job.getRef('/to'))}. ` +
                    `${count} required, ${inBay} available.`,
                {
                    reason: 'not_available',
                },
            );
        }
    }

    const ship = job.handle('/assignee');

    for (const [what, count] of shipUtil.unmessy(job.get('/cargo'))) {
        const inBay = job.getRefArrayElement(
            `/to/corps/${shipOwnerId}/storage`,
            what,
            0,
        );

        job.cfDecRefArrayElement(
            `/to/corps/${shipOwnerId}/storage`,
            what,
            Math.min(count, inBay),
        );
    }

    return true;
}

function routineDoDeliveryStart(model, click, args, warn) {
    const jobId = args.this;
    const jobData = model.get(`/${jobId}`);
    const shipId = args.ship;
    const shipOwnerId = model.rootNodeId(`/${shipId}/owner`);
    const fromStationId = model.rootNodeId(`/${jobId}/from`);

    const resources = shipUtil.synthesizePerCorpStationProps(
        fromStationId,
        shipOwnerId,
        model,
    );
    const simulatedResources = simulateAdd(
        model,
        `/${fromStationId}/corps/${shipOwnerId}/storage`,
        'stored',
        resources,
        shipUtil.unmessy(jobData.cargo),
    );

    const cargoKeys = shipUtil
        .unmessy(jobData.cargo ?? [])
        .map(([m]) => m.$ref);
    const unsupported = simulatedResources.unsupportedModules.find(([m]) =>
        cargoKeys.includes(m),
    );

    if (jobData.assignee && jobData.assignee?.$ref?.substring?.(1) !== shipId) {
        warn(`Job is already assigned.`, { jobId });
    }

    if (unsupported) {
        warn(
            `Not enough ${unsupported[1]} at ` +
                ` ${shipUtil.name(model, fromStationId)}.`,
            {
                reason: 'insufficient_resource',
                resources: unsupported[1],
            },
        );
    }

    model.set(`/${jobId}/assignee`, { $ref: `/${shipId}` });

    for (const [m, ct] of shipUtil.unmessy(jobData.cargo)) {
        model.cfIncRefArrayElement(
            `/${fromStationId}/corps/${shipOwnerId}/storage`,
            m,
            ct,
        );
    }
}

function routineDeleteJob(model, click, args, warn) {
    if (model.getRef(`/${args.this}/assignee`)?.$ref === `/${args.ship}`) {
        model.set(`/${args.this}`, null);
    }
}

function doRoutine(spec, extraArgs, model, click, warn) {
    if (typeof spec?.name !== 'string') {
        throw new Error(
            'doRoutine must have a name: ' + shipUtil.debugString(spec),
        );
    }

    const routineKey = `routine${shipUtil.capitalize(spec.name)}`;

    if (!routines[routineKey]) {
        throw new Error('No such routine: ' + spec.name);
    }

    return routines[routineKey](
        model,
        click,
        { ...spec.args, ...extraArgs },
        warn,
    );
}

const replacementWalker = {
    $ref: {},
    $array: {},
    $boolean: {},
    $clickDuration: {},
    $descrim: {
        buildResult: (s, v, { replacementMap, subwalk }) => {
            if (v === undefined) {
                return;
            }

            const [type, subval] = shipUtil.dnode(v);

            if (type === 'replacementTarget') {
                let newValue = replacementMap.get(subval.name);

                // `undefined` is used to indicate that an embedded automation
                // has shadowed this target. Such a target should just be left
                // alone.
                if (newValue !== undefined) {
                    return newValue;
                }
            }

            return shipUtil.buildDNode(type, subwalk);
        },
    },
    $int: {},
    $string: {},
    $struct: {
        before: (s, v, { replacementMap }) => {
            const oldMap = new Map();

            if (s.$replacements) {
                // The template itself contains a replacement-defining node.
                // Any replacements it defines become shadowed and should
                // not be replaced.

                for (const { name } of v.replacements ?? []) {
                    if (replacementMap.has(name)) {
                        oldMap.set(name, replacementMap.get(name));
                    }

                    replacementMap.set(name, undefined);
                }
            }

            return oldMap;
        },
        after: (s, v, { before: oldMap, replacementMap }) => {
            if (s.$replacements) {
                // We need to restore any replacements that may have become
                // shadowed.

                for (const { name } of v.replacements ?? []) {
                    replacementMap.delete(name);
                }

                for (const [key, value] of oldMap) {
                    replacementMap.set(key, value);
                }
            }
        },
    },
    $tuple: {},
    $zero: {},
};

// exported for testing
export function performReplacements(template, replacementMap) {
    return walkSpec(replacementWalker, shipOrderTemplate, template, {
        replacementMap,
    });
}

function instantiateOrder(
    model,
    shipId,
    ctx,
    orderSpec,
    template,
    replacementMap,
    skipDefaults,
    vm,
) {
    template = performReplacements(template, replacementMap ?? new Map());

    // First instantiate the given template.

    const [templateType, templateValue] = shipUtil.dnode(template);

    const templateSpec = shipUtil.extendSpec(orderSpec.$descrim[templateType]);

    const instantiatedValue = {};
    for (let [fieldName, fieldSpec] of Object.entries(templateSpec)) {
        if (fieldName.startsWith('$')) {
            continue;
        }

        fieldSpec = shipUtil.extendSpec(fieldSpec);

        const instantiatedField = fieldSpec?.$isExpression
            ? vm.evaluateSync(templateValue[fieldName], [
                  Vm.context(ctx),
                  Vm.context(ctx),
              ])
            : templateValue[fieldName];

        instantiatedValue[fieldName] = instantiatedField;
    }

    if (!skipDefaults) {
        // Now, derive the proper corporate default and augment the instantiated
        // template with appropriate corporate default automations.

        const corporateDefault = shipUtil.buildShipDefaultOrderTemplate(
            model,
            shipId,
            templateType,
        );

        if (!instantiatedValue.triggers) {
            instantiatedValue.triggers = {};
        }

        instantiatedValue.triggers.before = [
            ...(corporateDefault?.triggers?.before ?? []),
            ...(instantiatedValue?.triggers?.before ?? []),
        ];

        instantiatedValue.triggers.onFailure = [
            ...(instantiatedValue?.triggers?.onFailure ?? []),
            ...(corporateDefault?.triggers?.onFailure ?? []),
        ];

        instantiatedValue.triggers.onSuccess = [
            ...(instantiatedValue?.triggers?.onSuccess ?? []),
            ...(corporateDefault?.triggers?.onSuccess ?? []),
        ];
    }

    return shipUtil.buildDNode(
        templateType,
        stripZeroValues(instantiatedValue),
    );
}

function orderClaimJob(shipId, args, model, vm, click, apply, warn, orderId) {
    const job = model.get(args.which);

    if (!job) {
        warn(`Job no longer exists.`, {
            reason: 'not_found',
            jobId: args.which.$ref.substring(1),
        });

        // If the above didn't throw, consider this a success.
        completeOrder(true, shipId, args, model, vm, click, apply, warn);
    } else {
        const blockers = tagLib.jobClaimBlockers(
            { click, model },
            model.handle(args.which),
            model.handle(`/${shipId}`),
        );

        if (!blockers.isEmpty()) {
            warn(`Job tag violation.`, {
                reason: 'tag_violation',
                violations: blockers.responses,
                jobId: args.which.$ref.substring(1),
                jobData: job,
            });
        }

        if (job.onStart) {
            doRoutine(
                job.onStart,
                {
                    ship: shipId,
                    this: args.which.$ref.substring(1),
                },
                model,
                click,
                warn,
            );
        }

        if (!args.skipCorporateAutomation) {
            runCorporateOnClaim(
                shipId,
                args.which,
                model,
                vm,
                click,
                apply,
                warn,
                orderId,
            );
        }

        completeOrder(true, shipId, args, model, vm, click, apply, warn);

        // All claims of the same job go into a single conflict group so that a
        // 'winner' is selected randomly rather than all failing.
        return {
            conflictGroup: `claimJob_${model.rootNodeId(args.which)}`,
        };
    }
}

function orderFinishJob(shipId, args, model, vm, click, apply, warn) {
    const ship = model.handle(`/${shipId}`);
    const jobId = ship.rootNodeId('/orders/0/which');

    try {
        if (!model.get(`/${jobId}`)) {
            warn('Job no longer exists.', {
                jobId,
                reason: 'not_found',
            });
        }

        const jobDesc = shipUtil.humanJobDescription(jobId, model);

        const tryFinish =
            model.get(`/${jobId}/tryFinish`) ??
            model.get(`/${jobId}/checkComplete`); // <- old name

        if (tryFinish) {
            doRoutine(
                tryFinish,
                {
                    ship: shipId,
                    this: jobId,
                },
                model,
                click,
                warn,
            );
        }

        model.fireEvent({
            type: 'JobSucceeded',
            ship: { $ref: `/${shipId}` },
            job: { $ref: `/${jobId}` },
        });

        model.delete(`/${jobId}`);

        warn.notify(`"${jobDesc}" complete! 🎉`);
    } catch (e) {
        if (e.code !== 'ORDER_SIMULATION_WARNING') {
            shipUtil.unexpectedError(e);
        }

        let message = e.message;

        if (args.abandonOnFail) {
            model.fireEvent({
                type: 'JobFailed',
                ship: { $ref: `/${shipId}` },
                job: { $ref: `/${jobId}` },
            });

            const onAbandon = model.get(`/${jobId}/onAbandon`) ?? {
                name: 'deleteJob',
                args: { ship: shipId },
            };

            if (onAbandon) {
                try {
                    doRoutine(
                        onAbandon,
                        {
                            ship: shipId,
                            this: jobId,
                        },
                        model,
                        click,
                        warn,
                    );
                } catch (e) {
                    // Ignore errors.
                    console.error('Error in onAbandon', onAbandon, e);
                }
            }

            message += ' Job was abandoned, as ordered.';
            e.details.abandoned = true;
        } else {
            message += ' Job was preserved, as ordered.';
            e.details.abandoned = false;
        }

        warn(message, e.details);
    }

    completeOrder(true, shipId, args, model, vm, click, apply, warn);
}

function orderLoad(shipId, args, model, vm, click, apply, warn, orderId) {
    const ship = model.handle(`/${shipId}`);

    if (ship.get('/location/type') !== 'docked') {
        warn(`Not docked`, {
            reason: 'not_docked',
        });
    }

    if (!args.cargo) {
        warn.notify(
            `Finished loading cargo at ${ship.get('/location/where/name')}.`,
        );
        completeOrder(true, shipId, args, model, vm, click, apply, warn);
    } else {
        const thingToLoad = args.cargo[0][0];
        const thingToLoadData = model.get(thingToLoad);
        const shipOwnerId = ship.rootNodeId('/owner');

        const { cargoThroughput } = shipUtil.synthesizeShipProps(shipId, model);

        const throughputPerClick = Math.floor(
            cargoThroughput / shipUtil.CLICKS_PER_HOUR,
        );

        const amtAvailable = ship.getRefArrayElement(
            `/location/where/corps/${shipOwnerId}/storage`,
            thingToLoad,
            0,
        );

        const amtRequested = ship.getRefArrayElement(
            '/orders/0/cargo',
            thingToLoad,
            0,
        );

        const txfrCt = shipUtil.isNil(click)
            ? amtRequested
            : Math.min(throughputPerClick, amtRequested);

        if (amtAvailable < txfrCt) {
            warn(
                `No ${
                    thingToLoadData?.name ?? thingToLoad?.$ref
                } available at ` + `${ship.get('/location/where/name')}.`,
                {
                    reason: 'not_available',
                    regarding: {
                        whereId: ship.rootNodeId('/location/where'),
                    },
                },
            );
        }

        const shipResources = shipUtil.synthesizeShipProps(ship.get(), model);

        const storageAfterLoad = simulateAdd(
            model,
            `/${shipId}/storage`,
            'stored',
            shipResources,
            [[thingToLoad, txfrCt]],
        );

        if (storageAfterLoad.unsupportedModules.length > 0) {
            const violation = storageAfterLoad.unsupportedModules[0];
            warn(
                `Insufficient ship resources: ${violation[1]}` +
                    ` ${ship.name}`,
                {
                    reason: 'insufficient_resource',
                    resources: violation[1],
                },
            );
        }

        ship.cfInc(`/orderNotes/${orderId}/doneCt`, txfrCt);

        ship.cfIncRefArrayElement('/storage', thingToLoad, txfrCt);
        ship.cfDecRefArrayElement('/orders/0/cargo', thingToLoad, txfrCt);
        ship.cfDecRefArrayElement(
            `/location/where/corps/${shipOwnerId}/storage`,
            thingToLoad,
            txfrCt,
        );

        return {
            elapsedClicks: shipUtil.isNil(click)
                ? Math.ceil(txfrCt / throughputPerClick)
                : 1,
        };
    }
}

function orderInstall(shipId, args, model, vm, click, apply, warn) {
    const ship = model.handle(`/${shipId}`);
    const shipOwnerId = ship.getReferent('/owner');

    if (ship.get('/location/type') !== 'docked') {
        warn(`Not docked`, {
            reason: 'not_docked',
        });
    }

    if (!shipOwnerId) {
        throw new Error(`Ship ${ctx.id} has no owner?`);
    }

    const locationId = ship.getReferent('/location/where');

    const moduleToInstallRef = ship.getReferent('/orders/0/which');
    if (
        model.getRefArrayElement(
            `/${locationId}/corps/${shipOwnerId}/storage`,
            moduleToInstallRef,
            0,
        ) <= 0
    ) {
        warn(
            `Module ${shipUtil.name(
                model,
                moduleToInstallRef,
            )} not available at ` + `${shipUtil.name(model, locationId)}`,
            {
                reason: 'not_available',
            },
        );
    }

    // During a simulation, we could still end up here even if we aren't docked
    // anyplace.
    if (locationId) {
        model.cfDecRefArrayElement(
            `/${locationId}/corps/${shipOwnerId}/storage`,
            moduleToInstallRef,
            1,
        );
    }

    ship.cfIncRefArrayElement('/modules', moduleToInstallRef, 1);

    completeOrder(true, shipId, args, model, vm, click, apply, warn);
}

function orderTravel(shipId, args, model, vm, click, apply, warn) {
    const ship = model.handle(`/${shipId}`);

    const { type: locationType, startClick: locationStartClick } =
        ship.get('/location') ?? {};

    const travelDuration = travelTimeClicks(model, shipId);

    let elapsedClicks = 1;

    if (
        shipUtil.isNil(click) ||
        (locationType === 'traveling' &&
            click >= locationStartClick + travelDuration)
    ) {
        if (shipUtil.isNil(click)) {
            elapsedClicks = travelDuration;
        }

        model.set(`/${shipId}/location`, {
            type: 'docked',
            where: args.to,
        });

        warn.notify(`Arrived at ${ship.get('/orders/0/to/name')}.`);

        completeOrder(true, shipId, args, model, vm, click, apply, warn);
    } else if (locationType === 'docked') {
        const shipSystems = resourceBoundSubsystem(
            model,
            `/${shipId}/modules`,
            'installed',
            ship.get('/groundShipResources'),
        );

        const shipStorage = resourceBoundSubsystem(
            model,
            `/${shipId}/storage`,
            'stored',
            shipSystems.availableResources,
        );

        if (shipStorage.unsupportedModules.length > 0) {
            warn(`Cannot depart. Ship is overloaded.`);
        }

        model.set(`/${shipId}/location`, {
            type: 'traveling',
            from: { $ref: `/${ship.getReferent('/location/where')}` },
            to: { $ref: `/${ship.getReferent('/orders/0/to')}` },
            startClick: click,
        });
    }

    return { elapsedClicks };
}

function orderUninstall(shipId, args, model, vm, click, apply, warn) {
    const ship = model.handle(`/${shipId}`);
    const shipOwnerId = ship.getReferent('/owner');

    if (ship.get('/location/type') !== 'docked') {
        warn(`Not docked`, {
            reason: 'not_docked',
        });
    }

    if (!shipOwnerId) {
        throw new Error(`Ship ${ctx.id} has no owner?`);
    }

    const moduleToUninstallRef = ship.getReferent('/orders/0/which');
    if (ship.getRefArrayElement('/modules', moduleToUninstallRef, 0) <= 0) {
        warn(
            `Ship ${shipUtil.name(model, shipId)} has no module ` +
                `"${shipUtil.name(model, moduleToUninstallRef)}" installed.`,
            {
                reason: 'not_available',
            },
        );
    }

    const corpProps = shipUtil.perCorpStationStats(
        model,
        ship.getReferent('/location/where'),
        shipOwnerId,
    );

    const storageSystemAfterUnload = simulateAdd(
        model,
        `/${shipId}/location/where/${shipOwnerId}/storage`,
        'stored',
        corpProps.availableResources,
        [[moduleToUninstallRef, 1]],
    );

    const locationId = ship.getReferent('/location/where');

    const violation = storageSystemAfterUnload.unsupportedModules.find(
        ([id]) => id === `/${moduleToUninstallRef}`,
    );
    if (violation) {
        warn(
            `Insufficient "${violation[1]}" resource at` +
                ` ${shipUtil.name(model, locationId)}.`,
            {
                reason: 'insufficient_resource',
                resource: violation[1],
                text:
                    `Insufficient local resources: ${violation[1]}` +
                    ` ${shipUtil.name(model, locationId)}`,
                whereId: locationId,
            },
        );
    }

    ship.cfDecRefArrayElement('/modules', moduleToUninstallRef, 1);

    // During a simulation, we could still end up here even if we aren't docked
    // anyplace.
    if (locationId) {
        model.cfIncRefArrayElement(
            `/${locationId}/corps/${shipOwnerId}/storage`,
            moduleToUninstallRef,
            1,
        );
    }

    completeOrder(true, shipId, args, model, vm, click, apply, warn);
}

function orderUnload(shipId, args, model, vm, click, apply, warn, orderId) {
    const ship = model.handle(`/${shipId}`);

    if (ship.get('/location/type') !== 'docked') {
        warn(`Not docked`, {
            reason: 'not_docked',
        });
    }

    let elapsedClicks = 1;

    if (!args.cargo) {
        warn.notify(
            `Finished unloading cargo at ${ship.get('/location/where/name')}.`,
        );
        completeOrder(true, shipId, args, model, vm, click, apply, warn);
    } else {
        const thingToUnload = args.cargo[0][0];
        const thingToUnloadData = model.get(thingToUnload);
        const shipOwnerId = ship.rootNodeId('/owner');

        const { cargoThroughput } = shipUtil.synthesizeShipProps(shipId, model);

        const throughputPerClick = Math.floor(
            cargoThroughput / shipUtil.CLICKS_PER_HOUR,
        );

        const amtAvailable = ship.getRefArrayElement(
            `/storage`,
            thingToUnload,
            0,
        );

        const amtRequested = ship.getRefArrayElement(
            '/orders/0/cargo',
            thingToUnload,
            0,
        );

        const txfrCt = shipUtil.isNil(click)
            ? amtAvailable
            : Math.min(throughputPerClick, amtRequested);
        elapsedClicks = shipUtil.isNil(click)
            ? Math.ceil(txfrCt / throughputPerClick)
            : 1;

        if (amtAvailable < txfrCt) {
            warn(
                `No ${shipUtil.name(model, thingToUnload)} available on ` +
                    `${shipUtil.name(model, shipId)}.`,
                {
                    reason: 'not_available',
                },
            );
        }

        const corpProps = shipUtil.perCorpStationStats(
            model,
            ship.rootNodeId('/location/where'),
            shipOwnerId,
        );

        const storageAfterUnload = simulateAdd(
            model,
            `/${shipId}/location/where/${shipOwnerId}/storage`,
            'stored',
            corpProps.availableResources,
            [[thingToUnload, txfrCt]],
        );

        if (storageAfterUnload.unsupportedModules.length > 0) {
            const violation = storageAfterUnload.unsupportedModules[0];

            warn(
                `Insufficient station resources: ${violation[1]}` +
                    ` ${shipUtil.name(model, `/${shipId}/location/where`)}`,
                {
                    reason: 'insufficient_resource',
                    resources: violation[1],
                    whereId: ship.rootNodeId(`/location/where`),
                },
            );
        }

        ship.cfInc(`/orderNotes/${orderId}/doneCt`, txfrCt);

        ship.cfDecRefArrayElement('/storage', thingToUnload, txfrCt);
        ship.cfDecRefArrayElement('/orders/0/cargo', thingToUnload, txfrCt);
        ship.cfIncRefArrayElement(
            `/location/where/corps/${shipOwnerId}/storage`,
            thingToUnload,
            txfrCt,
        );
    }

    return { elapsedClicks };
}

function orderWait(shipId, args, model, vm, click, apply, warn) {
    const ship = model.handle(`/${shipId}`);

    let elapsedClicks = 1;

    if (shipUtil.isNil(click) || ship.get('/orders/0/duration', 0) <= 0) {
        if (shipUtil.isNil(click)) {
            elapsedClicks = ship.get('/orders/0/duration', 0);
        }

        warn.notify('Finished waiting.');
        completeOrder(true, shipId, args, model, vm, click, apply, warn);
    } else {
        ship.cfDec('/orders/0/duration', 1);
    }

    return { elapsedClicks };
}

function runCorporateOnClaim(
    shipId,
    jobRef,
    model,
    vm,
    click,
    apply,
    warn,
    orderId,
) {
    const { groupList, groupMap } = shipUtil.buildShipDetails(model, shipId);

    const jobData = model.get(jobRef);

    let automations = [];
    let ctx = [];
    for (const { onClaimJob } of groupList) {
        const groupAutomations = onClaimJob?.[jobData.type];

        if (groupAutomations) {
            automations = groupAutomations;
        }
    }

    for (const a of automations) {
        doAutomation(
            model,
            click,
            apply,
            vm,
            `/${shipId}/orders/${orderId}`,
            a,
            warn,
        );
    }
}

function validateOrder(o, warn) {
    try {
        validate(shipOrder, o);
    } catch (e) {
        if (!(e instanceof ValidationError)) {
            shipUtil.unexpectedError(e);
        }

        console.error(
            'Instantiated order from validation failed to validate',
            e,
        );

        warn(
            new OrderSimulationWarning(`Invalid order.`, {
                order: o,
                validationMessage: e.message,
                validationErrors: e.errors,
            }),
        );
    }
}

export function travelTimeClicks(model, shipData) {
    if (typeof shipData === 'string') {
        shipData = model.get(`/${shipData}`);
    }

    const shipOrders = shipUtil.unmessy(shipData?.orders);

    if (!shipOrders?.[0]?.to) {
        return;
    }

    const shipSystems = resourceBoundSubsystem(
        model,
        shipData.modules,
        'installed',
        shipData.groundShipResources,
    );

    const fromRef =
        shipData?.location?.type === 'traveling'
            ? shipData?.location?.from
            : shipData?.location?.where;

    const from = model.get(fromRef);
    const to = model.get(shipOrders?.[0]?.to);

    if (!from || !to) {
        throw new Error('Huh?');
    }

    const dX = (from.x ?? 0) - (to.x ?? 0);
    const dY = (from.y ?? 0) - (to.y ?? 0);
    const dist = Math.sqrt(dX * dX + dY * dY);

    const speedPerHr = shipSystems.availableResources.speed ?? 1;
    const speedPerClick = speedPerHr / shipUtil.CLICKS_PER_HOUR;

    return 1 + Math.ceil(dist / speedPerClick);
}
