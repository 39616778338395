import clone from 'clone';
import deepEqual from 'deep-equal';
import hash from 'object-hash';
import jsonPointer from 'json-pointer';
import * as shipUtil from '../utils/utils.mjs';

import { stripZeroValues } from './model.mjs';

/*
 * A syncle is a "synchronizable model". It is represented as a normal object
 * whose arrays are constrained to contain only `{ id, value }` elements. Paths
 * can then navigate arrays by either index or id. For example, for the object
 * `{ foo: [ { id: 'x', value: 'foo0' } ] }`, both `/foo/0` and `/foo/x` return
 * 'foo0'.
 *
 * Note that this is a superset of the data model represented by model.mjs,
 * which further constrains objects and arrays to not contain zero values.
 */
export default class Syncle {
    constructor(value) {
        this.value = clone(value);
    }

    applyEditPatch(ops) {
        for (const o of ops) {
            shipUtil.applyEdit(this, o);
        }
    }

    /*
     * Returns an array of edit operations (as defined by the Edit client
     * command) that would bring this Syncle into the state defined by the given
     * target value. Note that the target value should be a POJO and not itself
     * a Syncle.
     */
    createEditPatch(target, buildArrayEdits = Syncle.ArrayReplaceStrategy) {
        return createEditPatch(this.value, target, buildArrayEdits);
    }

    get(path, d) {
        if (typeof path === 'string') {
            path = jsonPointer.parse(path);
        }

        let node = this.value;
        while (path.length > 0) {
            const nextElement = path.shift();

            if (Array.isArray(node)) {
                node =
                    node[nextElement] ??
                    node.find(({ id }) => id === nextElement);

                node = node?.value;
            } else {
                node = node?.[nextElement];
            }
        }

        return node ?? d;
    }

    isASyncle() {
        return true;
    }

    pop(path) {
        let curVal = this.get(path);

        if (!Array.isArray(curVal)) {
            curVal = [];
        }

        this.set(path, curVal.slice(0, curVal.length - 1));

        return curVal[curVal.length - 1];
    }

    push(path, el) {
        let curVal = this.get(path);

        if (!Array.isArray(curVal)) {
            curVal = [];
        }

        this.set(path, [...curVal, el]);
    }

    set(path, pojo) {
        if (typeof path === 'string') {
            path = jsonPointer.parse(path);
        }

        let parent;
        let node = this.value;
        let traversedPath = [];
        let parentField;
        while (path.length > 0) {
            const nextElement = path.shift();

            if (typeof node !== 'object' || node === null) {
                node = /^\d+$/.test(nextElement) ? [] : {};

                if (parentField !== undefined) {
                    parent[parentField] = node;
                } else {
                    this.value = node;
                }
            }

            if (Array.isArray(node)) {
                let arraySlot;
                if (/^\d+$/g.test(nextElement)) {
                    if (!(nextElement in node)) {
                        node[nextElement] = { id: null };
                    }

                    arraySlot = node[nextElement];
                } else {
                    const elIdx = node.findIndex(
                        ({ id }) => id === nextElement,
                    );

                    if (elIdx === -1) {
                        throw new Error(
                            'No such array element: ' +
                                jsonPointer.compile(
                                    traversedPath.concat([nextElement]),
                                ),
                        );
                    }

                    arraySlot = node[elIdx];
                }

                parent = arraySlot;
                parentField = 'value';
                node = parent.value;
            } else {
                parent = node;
                parentField = nextElement;
                node = node?.[nextElement];
            }
        }

        if (parentField === undefined) {
            this.value = addIntermediateArrayElements(pojo);
        } else {
            parent[parentField] = addIntermediateArrayElements(pojo);
        }
    }

    toPojo() {
        return toPojo(this.value);
    }
}

Syncle.ArrayReplaceStrategy = (srcSyncleArray, targetPojoArray) => {
    if (
        deepEqual(
            srcSyncleArray.map(({ value }) => value),
            targetPojoArray,
        )
    ) {
        return [];
    }

    return [{ op: 'set', key: '', value: stripZeroValues(targetPojoArray) }];
};

function addIntermediateArrayElements(root) {
    if (Array.isArray(root)) {
        return root.map((value) => ({
            value: addIntermediateArrayElements(value),
        }));
    }

    if (typeof root === 'object' && root !== null) {
        return Object.fromEntries(
            Object.entries(root).map(([key, value]) => [
                key,
                addIntermediateArrayElements(value),
            ]),
        );
    }

    return root;
}

function toPojo(root) {
    if (Array.isArray(root)) {
        return root.map(({ value }) => toPojo(value));
    }

    if (typeof root !== 'object' || root === null) {
        return root;
    }

    return Object.fromEntries(
        Object.entries(root).map(([k, v]) => [k, toPojo(v)]),
    );
}

function createEditPatch(
    srcSyncleValue,
    targetPojo,
    buildArrayEdits,
    pathPrefix = '',
    patchAccum = [],
) {
    if (
        typeof srcSyncleValue !== typeof targetPojo ||
        (srcSyncleValue === null) !== (targetPojo === null) ||
        Array.isArray(srcSyncleValue) !== Array.isArray(targetPojo)
    ) {
        patchAccum.push({
            op: 'set',
            key: pathPrefix,
            value: stripZeroValues(targetPojo),
        });
    } else {
        if (Array.isArray(srcSyncleValue)) {
            const arrayEdits = buildArrayEdits(srcSyncleValue, targetPojo, {
                buildArrayEdits,
                path: pathPrefix,
            });

            for (const e of arrayEdits) {
                patchAccum.push({
                    ...e,
                    key: `${pathPrefix}${e.key}`,
                });
            }
        } else if (typeof srcSyncleValue === 'object') {
            if (srcSyncleValue === null) {
                // targetPojo must also be null. There is no change.
            } else {
                const allKeys = new Set([
                    ...Object.keys(srcSyncleValue),
                    ...Object.keys(targetPojo),
                ]);

                for (const k of allKeys) {
                    if (k in srcSyncleValue && !(k in targetPojo)) {
                        patchAccum.push({
                            op: 'set',
                            key: `${pathPrefix}/${k}`,
                            value: null,
                        });
                    } else if (k in targetPojo && !(k in srcSyncleValue)) {
                        patchAccum.push({
                            op: 'set',
                            key: `${pathPrefix}/${k}`,
                            value: stripZeroValues(targetPojo[k]),
                        });
                    } else {
                        createEditPatch(
                            srcSyncleValue[k],
                            targetPojo[k],
                            buildArrayEdits,
                            `${pathPrefix}/${k}`,
                            patchAccum,
                        );
                    }
                }
            }
        } else {
            if (srcSyncleValue !== targetPojo) {
                patchAccum.push({
                    op: 'set',
                    key: pathPrefix,
                    value: stripZeroValues(targetPojo),
                });
            }
        }
    }

    return patchAccum;
}
