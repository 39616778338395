import clone from 'clone';
import validate, { ValidationError } from '../shared/validate.mjs';
import walkSpec from '../shared/walk-spec.mjs';

import { expression } from '../specs.mjs';

// Tantive is just model- and spec-aware JSON that allows string names to be
// substituted for $refs.

export class TantiveError extends Error {
    constructor(msg) {
        super(msg);
    }
}

const compileWalker = {
    $ref: {
        buildResult: (s, v, { model }) => {
            if (typeof v === 'string') {
                if (typeof s === 'string') {
                    s = { kind: s };
                }

                const matches = model.getMatches({ ...s, name: v });

                if (matches.length === 0) {
                    throw new TantiveError(`No such ${s.kind}: ${v}`);
                }

                if (matches.length > 1) {
                    throw new Error(`Multiple ${s.kind} matches for: ${v}?`);
                }

                return { $ref: `/${matches[0]}` };
            }
        },
    },

    $array: {},
    $boolean: {},
    $clickDuration: {},
    $descrim: {},
    $int: {},
    $string: {},
    $struct: {},
    $tuple: {},
    $zero: {},
};

const decompileWalker = {
    $ref: {
        buildResult: (s, v, { model }) => {
            if (v?.$ref) {
                const entity = model.get(v.$ref);

                if (entity.name) {
                    return entity.name;
                }

                return v;
            }
        },
    },

    $array: {},
    $boolean: {},
    $clickDuration: {},
    $descrim: {},
    $int: {},
    $string: {},
    $struct: {},
    $tuple: {},
};

export function compile(t, model, spec = expression) {
    const result = walkSpec(compileWalker, spec, t, { model });
    validate(spec, result);
    return result;
}

export function decompile(c, model, spec = expression) {
    validate(spec, c);
    return walkSpec(decompileWalker, spec, c, { model });
}
