import clone from 'clone';
import jsonPointer from 'json-pointer';
import merge from 'extend';
import * as shipUtil from './utils/utils.mjs';
import tagLib from './shared/tags.mjs';
import Vm from './shared/vm.mjs';

import { ClientProblem } from './shared/vm.mjs';
import { isNil } from './utils/utils.mjs';

// A lexicon of schema annotations:
//     $array: { [elements: Schema] } - value will be an array whose elements
//           conform to the "elements" sub-schema
//     $categorize: alternative-subschema -> Maybe<String> - valid only on
//           $descrim 'alternatives' argument. Maps a single alternative to a
//           named category, or nil for default category
//     $context: game-type-name - hint that the contextual argument is expected
//           to be the named type. If omitted, contextual argument could be
//           anything
//     $descrim: {alternatives} - value will be a discrimination node
//     $eval: (arg, ctx, model, doCall) -> Any - Only valid on $descrim
//           alternative sub-schemas. Function to evaluate a value of this
//           schema type when it is used as an AST node for the vm. `arg` will
//           be the argument to the discriminated node value, `ctx` will be
//           an object of contextual functions as retrieved from Vm.context()
//           (possibly undefined), `model` is the game model, and `doCall`
//           allows sub-computation. If $eval synchronously returns something
//           other than undefined, that value is used as the result of its
//           computation. Otherwise, `doCall` must be used to defer until the
//           computation can be completed. `doCall` has type
//           ([...subExps], reduceFn, newCtx) -> Nil, where `subExps` is an
//           array of AST nodes that need evaluated before computation can
//           continue, `reduceFn` is a function to finalize a return value once
//           the resolved values of those sub expressions is available, and
//           `newCtx` is an object of contextual functions to replace the
//           current context object. If the latter is omitted, the current
//           context is maintained. The `reduceFn` has type (cb, ...subValues),
//           where subValues are the resolved values of the provided
//           sub-expressions in their provided order, and `cb` has type
//           (err, result) -> Nil and should be called exactly once to indicate
//           the result of the computation.
//     $expectedTypes: { ...fieldX: game-type-name-X } - hint that the
//           corresponding argument fields expect the named types. Omitted
//           fields could take any type. Can also be used on
//           pseudo-argument-fields like $array's "elements"
//     $label: String - hint about a human-friendly name to use to describe
//           values of the spec type
//     $prettyPrint: () => String - currently kinda gross and not worth
//           documenting, needs to get fixed up!
//     $priorityToBeDefault: Number - valid only within $descrim sub-schemas.
//           Hint about which alternative should be displayed as a pre-populated
//           choice when a populated discrimination node is missing. Higher
//           number means higher priority. If omitted, defaults to 0.
//     $ref: Matcher - value will be a $ref to a game object that matches the
//           provided Matcher
//     $shorthand: [...ShorthandElements] - valid only inside a $descrim
//           sub-schema. Provides a hint for an inline way to represent the
//           value that includes an indication that other sibling-options are
//           available without the need for a parent dropdown. Each
//           ShorthandElement must be one of:
//               * A literal string to be displayed as static text
//               * A { "placeholder": argument-field-name } object indicating a
//                 "slot" for the value of the named argument field
//               * A { "pivot": String } object indicating a literal string
//                 which, in context, will indicate which alternative is
//                 selected and should be displayed in a way to allow the user
//                 to interact with it to change alternatives. Must be present
//                 exactly once.
//     $type: String<game-type-name> - hint that the result of the expression
//           will be the given type. If omitted, type could be anything
//     $withContexts: { ...fieldX: vm-context-X } - hint that $eval will push
//           a new context on the context stack as it evaluates the fiven field.
//           If the new context is given prepended by an asterisk, e.g., '*Job',
//           then this context will be the root of a new context scope (i.e.,
//           previous context will be discarded in this part of the tree and the
//           new root of the stack will be just the given context (without the
//           asterisk). This is used for expression literals that will not be
//           evaluated immediately, but rather executed in a different context
//           later.)

// Highest possible default.
const DPRIORITY_SURE_THING = Number.MAX_SAFE_INTEGER;

// A level of default-priority for expressions that operate on a specific kind
// of entity (like a "job" or a "ship") and take zero arguments.
const DPRIORITY_SPECIFIC_NO_ARGS = 1000;

// A level of default-priority for expressions that operate on a specific kind
// of entity (like a "job" or a "ship") and also take arguments.
const DPRIORITY_SPECIFIC_WITH_ARGS = 750;

// TODO: I'm not actually convinced `inductive` is accomplishing anything that
// the y-combinator couldn't do? Look into this.
export const specs = shipUtil.inductive((specs) => ({
    accountPreferences: {
        $permission: '/{{rootId}}/owner',
        notifications: {
            discord: {
                $label: 'Discord',

                urgent: {
                    $label: 'Urgent Notifications',
                    $descrim: {
                        immediate: { $label: 'Immediate' },
                        digest: { $label: 'Digest' },
                        ignore: { $label: 'Ignore' },
                    },
                },
                standard: {
                    $label: 'Standard Notifications',
                    $descrim: {
                        immediate: { $label: 'Immediate' },
                        digest: { $label: 'Digest' },
                        ignore: { $label: 'Ignore' },
                    },
                },
            },
        },
    },
    automation: {
        $descrim: {
            appendOrder: {
                $context: ['ship', 'shipOrder'],
                $withContexts: {
                    order: '*shipOrderTemplate',
                },
                $replacements: true,

                order: { $extend: specs.shipOrderTemplate },
                replacements: {
                    $label: 'Replacements',
                    $array: {
                        elements: {
                            name: {
                                $string: {
                                    minLength: 1,
                                    maxLength: 50,
                                },
                            },
                            value: { $extend: specs.expression },
                        },
                    },
                },
                skipDefaults: { $boolean: true, $label: 'Skip Defaults' },

                $prettyPrint: ({ order }, { model, prettyPrint }) => {
                    const { bounded } = prettyPrint(
                        specs.shipOrderTemplate(),
                        order,
                    );
                    return 'Append order: ' + bounded;
                },
            },
            fallback: {
                try: specs.automations,
                fallback: specs.automations,
            },
            if: {
                $expectedTypes: {
                    condition: 'boolean',
                },
                condition: {
                    $label: 'Condition',
                    $extend: specs.expression,
                },
                action: specs.automations,
                otherwise: {
                    $optional: true,
                    $extend: specs.automations,
                },
            },
            insertOrderAfter: {
                $context: 'shipOrder',
                $withContexts: {
                    order: 'shipOrderTemplate',
                },
                $replacements: true,

                order: { $extend: specs.shipOrderTemplate },
                replacements: {
                    $label: 'Replacements',
                    $array: {
                        elements: {
                            name: {
                                $string: {
                                    minLength: 1,
                                    maxLength: 50,
                                },
                            },
                            value: { $extend: specs.expression },
                        },
                    },
                },
                skipDefaults: { $boolean: true, $label: 'Skip Defaults' },

                $prettyPrint: ({ order }, { model, prettyPrint }) => {
                    const { bounded } = prettyPrint(
                        specs.shipOrderTemplate(),
                        order,
                    );
                    return 'Insert order after: ' + bounded;
                },

                $dynamicHints: (base, { value }) => {
                    const [orderType] = shipUtil.dnode(value?.order, '');

                    return {
                        ...base,

                        $withContexts: {
                            order: `shipOrderTemplate${shipUtil.capitalize(orderType)}`,
                        },
                    };
                },
            },
            insertOrderBefore: {
                $context: 'shipOrder',
                $withContexts: {
                    order: 'shipOrderTemplate',
                },
                $replacements: true,

                order: { $extend: specs.shipOrderTemplate },
                replacements: {
                    $label: 'Replacements',
                    $array: {
                        elements: {
                            name: {
                                $string: {
                                    minLength: 1,
                                    maxLength: 50,
                                },
                            },
                            value: { $extend: specs.expression },
                        },
                    },
                },
                skipDefaults: { $boolean: true, $label: 'Skip Defaults' },

                $prettyPrint: ({ order }, { model, prettyPrint }) => {
                    const { bounded } = prettyPrint(
                        specs.shipOrderTemplate(),
                        order,
                    );
                    return 'Insert order before: ' + bounded;
                },
            },
        },
    },
    automations: {
        $array: {
            elements: specs.automation,
        },
    },
    cargoManifest: cargoManifestSpec(specs, (field, type) => ({
        [field]: type,
    })),
    corporationPreferences: {
        $permission: '/{{rootId}}/write',
        shipGroups: {
            $label: 'Ship Groups',
            $array: {
                elements: {
                    $withContexts: {
                        onClaimJob: 'shipOrderClaim',
                        onIdle: 'ship',
                    },

                    name: {
                        $label: 'Group Name',
                        $string: {
                            minLength: 1,
                            maxLength: 50,
                        },
                    },
                    inheritsFrom: {
                        $label: 'Inherits',
                        $string: {
                            $placeholder: 'Parent Group',
                            minLength: 0,
                            maxLength: 50,
                        },
                    },
                    onClaimJob: {
                        $label: 'On Job Claim...',

                        delivery: {
                            $label: 'Delivery Job',
                            $optional: true,
                            $extend: specs.automations,
                        },
                    },
                    onIdle: {
                        $label: 'On Idle...',
                        $optional: true,
                        $extend: specs.automations,
                    },
                    orderDefaults: {
                        $label: 'Order Type Defaults',

                        ...Object.fromEntries(
                            Object.entries(
                                shipOrdersSpec(
                                    specs,
                                    (field, type, typeName) => {
                                        return {
                                            $expectedTypes: {
                                                [field]: typeName,
                                            },

                                            [field]: {
                                                $extend: (base) => ({
                                                    ...base,
                                                    ...specs.expression(),
                                                    $descrim: {
                                                        ...specs.expression()
                                                            .$descrim,

                                                        unspecified: {
                                                            $label: 'Unspecified',
                                                            $literal: true,
                                                            $priorityToBeDefault:
                                                                DPRIORITY_SURE_THING,

                                                            $prettyPrint:
                                                                '<unspecified>',
                                                            $eval: (
                                                                { job },
                                                                shipOrder,
                                                                model,
                                                                call,
                                                                { path },
                                                            ) => {
                                                                throw new ClientProblem(
                                                                    'Not specified',
                                                                );
                                                            },
                                                        },
                                                    },
                                                }),
                                            },
                                        };
                                    },
                                ).$descrim,
                            ).map(([key, spec]) => [
                                key,
                                {
                                    ...spec,
                                    $optional: true,
                                },
                            ]),
                        ),
                    },
                },
            },
        },
    },
    expression: {
        $isExpression: true,
        $descrim: {
            $isExpression: true,
            $categorize: (spec, name) => {
                function isExpression(e) {
                    return !!shipUtil.extendSpec(e).$isExpression;
                }

                function hasSubexp(e) {
                    if (isNil(e)) {
                        return false;
                    }

                    if (isExpression(e)) {
                        return true;
                    }

                    for (const [key, value] of Object.entries(e)) {
                        if (!key.startsWith('$')) {
                            if (
                                isExpression(value) ||
                                (typeof value === 'object' && hasSubexp(value))
                            ) {
                                return true;
                            }
                        }
                    }

                    if (
                        hasSubexp(e?.$array?.elements) ||
                        hasSubexp(e?.$descrim)
                    ) {
                        return true;
                    }
                }

                return name.startsWith('from')
                    ? 'From...'
                    : hasSubexp(spec)
                      ? 'Calculate...'
                      : null;
            },

            allOf: {
                $type: 'boolean',
                $label: 'Each and every...',

                conditions: {
                    $array: {
                        $expectedTypes: {
                            elements: 'boolean',
                        },
                        $minLength: 1,
                        elements: specs.expression,
                    },
                },

                $eval: ({ conditions }, ctx, model, doCall) => {
                    doCall(conditions, (cb, ...results) => {
                        cb(
                            null,
                            results.every((x) => x),
                        );
                    });
                },
            },
            anyOf: {
                $type: 'boolean',
                $label: 'At least one...',

                alternatives: {
                    $array: {
                        $expectedTypes: {
                            elements: 'boolean',
                        },
                        $minLength: 1,
                        elements: specs.expression,
                    },
                },

                $eval: ({ alternatives }, ctx, model, doCall) => {
                    doCall(alternatives, (cb, ...results) =>
                        cb(
                            null,
                            results.some((x) => x),
                        ),
                    );
                },
            },
            best: {
                $label: 'Best...',
                $expectedTypes: {
                    list: 'list *',
                },
                list: { $extend: specs.expression },

                $eval: ({ list }, ctx, model, doCall, { evalSync }) => {
                    list = evalSync(list);

                    if (list.length === 0) {
                        throw new ClientProblem('List is empty.');
                    }

                    return list[0];
                },
            },
            cargo: {
                $context: 'job',
                $type: 'cargoManifest',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Cargo Manifest',
                $prettyPrint: prettyPlaceholder('cargo manifest'),
                $eval: (args, j) => shipUtil.unmessy(j.cargo),
            },
            cargoCt: {
                $context: 'job',
                $type: 'number',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Cargo Palette Count',
                $prettyPrint: prettyPlaceholder('cargo palette count'),
                $eval: (args, j) => {
                    return j.cargo
                        .map(({ value: pair }) => pair)
                        .map(([k, v]) => v)
                        .map(({ value: count }) => count)
                        .reduce((x, y) => x + y, 0);
                },
            },
            claimableBy: {
                $context: 'job',
                $type: 'boolean',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_WITH_ARGS,

                $label: 'Claimable by...',

                $expectedTypes: {
                    ship: 'ship',
                },

                ship: { $extend: specs.expression },

                $eval: (
                    { ship },
                    job,
                    model,
                    doCall,
                    { dynamicContextStack, evalSync, path },
                ) => {
                    const oldShip = ship;
                    ship = evalSync(ship);

                    const jobHandle = model.handle(path);
                    const shipHandle = model.handle(ship.$ref);

                    return (
                        !job.assignee &&
                        tagLib
                            .jobClaimBlockers({ model }, jobHandle, shipHandle)
                            .isEmpty()
                    );
                },
            },
            destLocation: {
                $context: ['hop', 'job', 'shipOrderClaim', 'shipOrderTravel'],
                $type: 'station',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Destination Location',
                $prettyPrint: prettyPlaceholder('destination location'),
                $eval: (o, e, model) =>
                    isKind(e, 'job') || isKind(e, 'hop')
                        ? e.to
                        : shipUtil.branch(e, {
                              claimJob: ({ which }) => model.get(which)?.to,
                              travel: ({ to }) => to,
                          }),
            },
            destLocationIs: {
                $context: ['job', 'shipOrderClaim'],
                $type: 'boolean',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_WITH_ARGS,

                $label: 'Destination Location Is...',
                $prettyPrint: ({ destination }, { model }) => {
                    const destObj = model.get(destination);
                    const root = `dest = "${destObj?.name}"`;
                    return root;
                },
                $eval: ({ destination }, e, model, doCall, { evalSync }) => {
                    destination = evalSync(destination);

                    const actual = isKind(e, 'job')
                        ? e.to
                        : model.get(e.job)?.to;

                    /*if (!job.to) {
						throw new ClientProblem('No destination location.');
					}*/

                    return actual?.$ref === destination?.$ref;
                },

                destination: { $ref: 'station' },
            },
            distance: {
                $type: 'number',
                $shorthand: [
                    { pivot: 'Distance between' },
                    ' ',
                    { placeholder: 'location1' },
                    ' and ',
                    { placeholder: 'location2' },
                ],
                $expectedTypes: {
                    location1: ['ship', 'station'],
                    location2: ['ship', 'station'],
                },

                location1: specs.expression,
                location2: specs.expression,

                $eval: (
                    { location1, location2 },
                    ctx,
                    model,
                    doCall,
                    { evalSync },
                ) => {
                    function coerceToStation(exp) {
                        if (shipUtil.hasKind(exp, 'ship')) {
                            if (exp?.location?.type !== 'docked') {
                                throw new ClientProblem('Ship is not docked.');
                            }

                            return model.get(exp.location.where);
                        }

                        return exp;
                    }

                    doCall([location1, location2], (cb, loc1Ref, loc2Ref) => {
                        const loc1 = coerceToStation(model.get(loc1Ref));
                        const loc2 = coerceToStation(model.get(loc2Ref));

                        const { x: x1 = 0, y: y1 = 0 } = loc1;
                        const { x: x2 = 0, y: y2 = 0 } = loc2;

                        if (
                            typeof x1 !== 'number' ||
                            typeof y1 !== 'number' ||
                            typeof x2 !== 'number' ||
                            typeof y2 !== 'number'
                        ) {
                            cb(null, NaN);
                        }

                        const dX = x2 - x1;
                        const dY = y2 - y1;

                        cb(null, Math.sqrt(dX * dX + dY * dY));
                    });
                },
            },
            distanceInherent: {
                $type: 'number',
                $context: ['hop', 'job'],
                $eval: (args, { from, to }, model) => {
                    const { x: x1 = 0, y: y1 = 0 } = model.get(from);
                    const { x: x2 = 0, y: y2 = 0 } = model.get(to);

                    const dX = x2 - x1;
                    const dY = y2 - y1;

                    return Math.sqrt(dX * dX + dY * dY);
                },
            },
            divide: {
                $type: 'number',
                $prettyPrint: leftAssocPrettyPrint(
                    'divide',
                    '/',
                    'numerator',
                    'denominator',
                ),
                $shorthand: [
                    { pivot: 'Divide' },
                    ' ',
                    { placeholder: 'numerator' },
                    ' by ',
                    { placeholder: 'denominator' },
                ],
                $expectedTypes: {
                    numerator: 'number',
                    denominator: 'number',
                },
                numerator: specs.expression,
                denominator: specs.expression,
            },
            dockedAt: {
                $context: 'ship',
                $type: 'boolean',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_WITH_ARGS,

                $label: 'Docked At...',
                $prettyPrint: ({ location }, { model }) => {
                    const locObj = model.get(location);
                    const root = `dock = "${locObj?.name}"`;
                    return root;
                },
                $eval: ({ location }, ship, model, doCall, { evalSync }) => {
                    location = evalSync(location);

                    if (!ship.location) {
                        throw new ClientProblem('No location.');
                    }

                    return (
                        ship.location.type === 'docked' &&
                        ship.location.where?.$ref === location?.$ref
                    );
                },

                location: { $ref: 'station' },
            },
            dockedStation: {
                $context: 'ship',
                $type: 'station',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Docked Station',
                $prettyPrint: prettyPlaceholder('docked station'),
                $eval: (args, ship, model, d, { path }) => {
                    if (!ship.location) {
                        throw new ClientProblem('No location.');
                    }

                    if (ship.location.type !== 'docked') {
                        throw new ClientProblem('Not docked');
                    }

                    return ship.location.where;
                },
            },
            entityItself: {
                $eval: (a, b, c, d, { path }) => {
                    if (!path) {
                        throw new ClientProblem('Not an entity.');
                    }

                    return { $ref: path };
                },
            },
            eq: {
                $type: 'boolean',
                $label: 'Is Equal',
                $prettyPrint: leftAssocPrettyPrint('eq', '=', 'left', 'right'),
                $shorthand: [
                    { placeholder: 'left' },
                    ' ',
                    { pivot: 'is' },
                    ' ',
                    { placeholder: 'right' },
                ],
                left: specs.expression,
                right: specs.expression,
            },
            fromJob: {
                $context: 'shipOrderClaim',
                $label: 'From claimed job...',
                $type: ({ get }) => expressionType(get),

                $prettyPrint: ({ get }, { model, prettyPrint }) => {
                    const { bounded: exp } = prettyPrint(
                        specs.expression(),
                        get,
                    );

                    const result = `${exp} from <claimed job>`;

                    return result;
                },
                $eval: ({ get }, shipOrder, model, doCall, { path }) => {
                    shipUtil.assertDnodeType(shipOrder, 'claimJob');

                    doCall(
                        [get],
                        (cb, result) => cb(null, result),
                        Vm.context(shipOrder.which?.$ref),
                    );
                },

                $expectedTypes: { get: 'inherit' },
                $withContexts: { get: 'job' },

                get: specs.expression,
            },
            fromShip: {
                $context: 'shipOrder',
                $label: 'From ship...',
                $type: ({ get }) => expressionType(get),

                $prettyPrint: ({ get }, { model, prettyPrint }) => {
                    const { bounded } = prettyPrint(specs.expression(), get);
                    const result = `${bounded} from <ship>`;
                    return result;
                },
                $eval: ({ get }, shipOrder, model, doCall, { path }) => {
                    const shipId = jsonPointer.parse(path)[0];
                    const shipContext = Vm.context(`/${shipId}`);

                    doCall(
                        [get],
                        (cb, result) => cb(null, result),
                        shipContext,
                    );
                },

                $expectedTypes: { get: 'inherit' },
                $withContexts: { get: 'ship' },

                get: specs.expression,
            },
            fromPreviousContext: {
                $label: 'From previous context...',
                $type: ({ get }) => expressionType(get),

                $withContexts: { get: null },

                get: {
                    $extend: specs.expression,
                    $label: 'Get',
                },
                previousContext: {
                    $label: 'Context',
                    $int: [0, 100],
                },

                $expectedTypes: { get: 'inherit' },

                $dynamicHints: (base, { lexicalContextStack, value }) => {
                    return {
                        ...base,

                        previousContext: {
                            ...base.previousContext,

                            $enum: lexicalContextStack
                                .slice(0, lexicalContextStack.length - 1)
                                .reverse()
                                .map(([name, vmContext], i) => ({
                                    label: `${name ?? vmContext}`,
                                    value: i + 1,
                                })),
                        },
                        $withContexts: {
                            get:
                                lexicalContextStack[
                                    lexicalContextStack.length -
                                        value.previousContext -
                                        1
                                ]?.[1] ?? null,
                        },
                    };
                },

                $prettyPrint: (
                    { get, previousContext },
                    { model, prettyPrint },
                ) => {
                    const { bounded } = prettyPrint(specs.expression(), get);
                    const result = `${bounded} from #${previousContext}`;
                    return result;
                },
                $eval: (
                    { get, previousContext },
                    shipOrder,
                    model,
                    doCall,
                    { evalSync, path },
                ) => {
                    previousContext = evalSync(previousContext);

                    doCall(
                        [get],
                        (cb, result) => cb(null, result),
                        previousContext * -1,
                    );
                },
            },
            gt: {
                $type: 'boolean',
                $label: 'Greater Than',
                $prettyPrint: leftAssocPrettyPrint('gt', '>', 'left', 'right'),
                $shorthand: [
                    { placeholder: 'left' },
                    ' ',
                    { pivot: '>' },
                    ' ',
                    { placeholder: 'right' },
                ],
                left: specs.expression,
                right: specs.expression,

                $eval: ({ left, right }, ctx, model, call) => {
                    call([left, right], (cb, lV, rV) => {
                        if (typeof lV !== 'number' || isNaN(lV)) {
                            cb(
                                new ClientProblem(
                                    'Not a number: ' +
                                        shipUtil.debugString(left),
                                ),
                            );
                        } else if (typeof rV !== 'number' || isNaN(rV)) {
                            cb(
                                new ClientProblem(
                                    'Not a number: ' +
                                        shipUtil.debugString(right),
                                ),
                            );
                        } else {
                            cb(null, lV > rV);
                        }
                    });
                },
            },
            hasTag: {
                $context: ['job'],
                $type: 'boolean',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_WITH_ARGS,

                $label: 'Has tag...',
                $prettyPrint: ({ tag }) => `tagged ${JSON.stringify(tag)}`,
                $eval: ({ tag }, job, model, call, { evalSync }) => {
                    tag = evalSync(tag);

                    return (job?.tags ?? []).some(
                        ({ value }) =>
                            value.toLowerCase() === tag.toLowerCase(),
                    );
                },

                tag: { $string: [1, 30] },
            },
            isEmpty: {
                $label: 'Has None...',
                $type: 'boolean',

                $expectedTypes: {
                    list: 'list *',
                },

                list: specs.expression,
                $eval: ({ list }) => {
                    return list.length === 0;
                },
            },
            job: {
                $type: 'job',
                $context: 'shipOrderClaim',

                $eval: (node, shipOrder) => {
                    return shipOrder.which;
                },
            },
            jobs: {
                $type: 'list job',
                $label: 'Available Jobs',

                $withContexts: {
                    filters: 'job',
                    order: 'job',
                },

                order: {
                    $label: 'Sort Order',
                    $default: () => shipUtil.buildDNode('reward'),

                    $expectedTypes: {
                        order: ['number', 'string'],
                    },

                    order: {
                        $label: 'Ordering',
                        $extend: specs.expression,
                    },
                    direction: {
                        $label: 'Direction',
                        $descrim: {
                            ascending: { $label: 'Ascending' },
                            descending: { $label: 'Descending' },
                        },
                    },
                },
                filters: {
                    $label: 'Filters',
                    $array: {
                        $expectedTypes: {
                            elements: 'boolean',
                        },
                        elements: specs.expression,
                    },
                },

                $eval: (
                    { filters, order },
                    shipOrder,
                    model,
                    call,
                    { evalSync, path },
                ) => {
                    return filteredAndOrdered(
                        evalSync,
                        model,
                        {
                            kind: 'job',
                            assignee: { $exists: false },
                        },
                        filters,
                        order,
                    );
                },
            },
            literalCargo: {
                $type: 'cargoManifest',
                $label: 'Cargo...',
                $literal: true,

                $prettyPrint: ({ cargo }, { model }) => {
                    cargo = shipUtil.unmessy(cargo ?? []);

                    return cargo.length === 0
                        ? '<empty>'
                        : '[' +
                              cargo
                                  .map(
                                      ([good, ct]) =>
                                          `${ct} ${model.get(good)?.name}`,
                                  )
                                  .join(', ') +
                              ']';
                },
                $eval: (
                    { cargo },
                    shipOrder,
                    model,
                    call,
                    { evalSync, path },
                ) => {
                    return shipUtil.unmessy(cargo ?? []);
                },

                cargo: {
                    $extend: specs.cargoManifest,
                },
            },
            literalJob: {
                $type: 'job',
                $label: 'Job...',
                $literal: true,

                $prettyPrint: ({ job: ref }, { model }) => {
                    const unbounded = shipUtil.humanDescription(ref, model);

                    return unbounded;
                },
                $eval: (
                    { job },
                    shipOrder,
                    model,
                    call,
                    { evalSync, path },
                ) => {
                    return job;
                },

                job: { $ref: 'job' },
            },
            literalStation: {
                $type: 'station',
                $label: 'Station...',
                $literal: true,

                $prettyPrint: ({ station: ref }, { model }) => {
                    const entity = model.get(ref);

                    const name = entity?.name ?? '<None>';
                    const bounded = name.includes(' ') ? `'${name}'` : name;

                    return bounded;
                },
                $eval: ({ station }, shipOrder, model, call, { path }) => {
                    return station;
                },

                station: { $ref: 'station' },
            },
            location: {
                $descrim: {
                    $expectedTypes: {
                        ship: 'ship',
                        station: 'station',
                    },
                    ship: { $extend: specs.expression },
                    station: { $extend: specs.expression },
                },
                $eval: (value, order, model, call, { evalSync }) => {
                    const [, subvalue] = shipUtil.dnode(value);
                    return evalSync(subvalue);
                },
            },
            lt: {
                $type: 'boolean',
                $label: 'Less Than',
                $prettyPrint: leftAssocPrettyPrint('lt', '<', 'left', 'right'),
                $shorthand: [
                    { placeholder: 'left' },
                    ' ',
                    { pivot: '<' },
                    ' ',
                    { placeholder: 'right' },
                ],
                left: specs.expression,
                right: specs.expression,

                $eval: ({ left, right }, ctx, model, call) => {
                    call([left, right], (cb, lV, rV) => {
                        if (typeof lV !== 'number' || isNaN(lV)) {
                            cb(
                                new ClientProblem(
                                    'Not a number: ' +
                                        shipUtil.debugString(left),
                                ),
                            );
                        } else if (typeof rV !== 'number' || isNaN(rV)) {
                            cb(
                                new ClientProblem(
                                    'Not a number: ' +
                                        shipUtil.debugString(right),
                                ),
                            );
                        } else {
                            cb(null, lV < rV);
                        }
                    });
                },
            },
            neq: {
                $type: 'boolean',
                $label: 'Is Not Equal',
                $prettyPrint: leftAssocPrettyPrint(
                    'eq',
                    '=/=',
                    'left',
                    'right',
                ),
                $shorthand: [
                    { placeholder: 'left' },
                    ' ',
                    { pivot: 'is not' },
                    ' ',
                    { placeholder: 'right' },
                ],
                left: specs.expression,
                right: specs.expression,
            },
            nextHop: {
                $context: 'ship',
                $type: 'station',
                $label: 'Next Navigation Hop',
                $expectedTypes: {
                    hopFilter: 'boolean',
                    stationFilter: 'boolean',
                    to: 'station',
                },
                $withContexts: {
                    hopFilter: 'hop',
                    stationFilter: 'station',
                },

                to: {
                    $extend: specs.expression,
                    $label: 'Final Destination',
                },
                stationFilter: {
                    $extend: specs.expression,
                    $optional: true,
                    $label: 'Station Filter',
                },
                hopFilter: {
                    $extend: specs.expression,
                    $label: 'Hop Filter',
                },

                $eval: (args, ship, model, call) => {
                    if (ship.location.type !== 'docked') {
                        throw new ClientProblem('Ship must be docked.');
                    }

                    if (ship.location.where.$ref === args.to.$ref) {
                        const { name } = model.get(args.to);
                        throw new ClientProblem('Already at ' + name);
                    }

                    call(
                        [
                            shipUtil.buildDNode('shortestPath', {
                                from: ship.location.where,
                                to: args.to,
                                stationFilter: args.stationFilter,
                                hopFilter: args.hopFilter,
                            }),
                        ],
                        (cb, path) => {
                            // path[0] will be the current location, so we start at
                            // path[1].
                            if (!path[1]) {
                                cb(new ClientProblem('No appropriate path.'));
                            } else {
                                cb(null, { $ref: path[1] });
                            }
                        },
                    );
                },
            },
            operatingRange: {
                $context: 'ship',
                $type: 'number',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Operating Range',
                $prettyPrint: prettyPlaceholder('op range'),
                $eval: (args, ship, model) => {
                    const shipProps = shipUtil.synthesizeShipProps(ship, model);
                    return shipProps.operatingRange;
                },
            },
            randomStation: {
                $type: 'station',
                $label: 'Random Station',

                $prettyPrint: prettyPlaceholder('random station'),
                $eval: ({ station }, shipOrder, model, call, { path }) => {
                    const stationIds = model.getMatches({ kind: 'station' });

                    return { $ref: `/${shipUtil.pick(stationIds)}` };
                },
            },
            replacementTarget: {
                $context: 'shipOrderTemplate',
                $label: 'Replacement Target',

                name: {
                    $string: {
                        minLength: 1,
                        maxLength: 50,
                    },
                },
            },
            reward: {
                $context: 'job',
                $type: 'number',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Reward Amount',
                $prettyPrint: prettyPlaceholder('reward'),
                $eval: (args, j, model) => shipUtil.jobReward(j, model),
            },
            ship: {
                $context: 'shipOrder',
                $type: 'ship',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $prettyPrint: prettyPlaceholder('ship'),
                $eval: (args, shipOrder, model, call, { path }) => ({
                    $ref: `/${jsonPointer.parse(path)[0]}`,
                }),
            },
            shortestPath: {
                $type: 'list station',
                $label: 'Navigation Path',
                $expectedTypes: {
                    stationFilter: 'boolean',
                    hopFilter: 'boolean',
                    from: ['ship', 'station'],
                    to: ['ship', 'station'],
                },
                $withContexts: {
                    stationFilter: 'station',
                    hopFilter: 'hop',
                },

                from: {
                    $extend: specs.expression,
                },
                to: {
                    $extend: specs.expression,
                },
                stationFilter: {
                    $extend: specs.expression,
                    $optional: true,
                },
                hopFilter: {
                    $extend: specs.expression,
                },

                $eval: (
                    { from, to, stationFilter, hopFilter },
                    shipOrder,
                    model,
                    call,
                    { evalSync, path },
                ) => {
                    from = evalSync(from);
                    to = evalSync(to);

                    if (shipUtil.hasKind(from, 'ship')) {
                        const ship = from;
                        from = from?.location?.where;

                        if (!from) {
                            throw new ClientProblem(
                                'Ship ' + ship.name + " isn't docked.",
                            );
                        }
                    }

                    if (shipUtil.hasKind(to, 'ship')) {
                        const ship = to;
                        to = to?.location?.where;

                        if (!to) {
                            throw new ClientProblem(
                                'Ship ' + ship.name + " isn't docked.",
                            );
                        }
                    }

                    const stations = [from.$ref, to.$ref];
                    for (const stationId of model.getMatches({
                        kind: 'station',
                    })) {
                        if (
                            `/${stationId}` === from.$ref ||
                            `/${stationId}` === to.$ref
                        ) {
                            continue;
                        }

                        // Note that these calls don't happen right away, we're
                        // queuing them up.
                        call(
                            [stationFilter ?? true],
                            (cb, x) => cb(null, x),
                            Vm.context(`/${stationId}`),
                            (result) => {
                                if (result) {
                                    stations.push(`/${stationId}`);
                                }
                            },
                        );
                    }

                    const hops = [];
                    call(
                        [],
                        () => {
                            for (let i = 0; i < stations.length; i++) {
                                for (let j = i + 1; j < stations.length; j++) {
                                    // Note that these calls don't happen right away,
                                    // we're queuing them up.
                                    call(
                                        [hopFilter ?? true],
                                        (cb, x) => cb(null, x),
                                        Vm.context({
                                            kind: ['hop'],
                                            from: { $ref: stations[i] },
                                            to: { $ref: stations[j] },
                                        }),
                                        (result) => {
                                            if (result) {
                                                hops.push([
                                                    stations[i],
                                                    stations[j],
                                                ]);
                                            }
                                        },
                                    );
                                }
                            }
                        },
                        null,
                        () => {},
                    );

                    let bestPath;
                    let bestDist = Number.MAX_SAFE_INTEGER;

                    function explore(cb, { path, distance }) {
                        if (path[path.length - 1] === to.$ref) {
                            if (distance < bestDist) {
                                bestPath = path;
                                bestDist = distance;
                            }

                            cb();
                        } else {
                            let callCt = 0;
                            for (const h of hops.filter((h) =>
                                h.includes(path[path.length - 1]),
                            )) {
                                const [next] = h.filter(
                                    (p) => p !== path[path.length - 1],
                                );

                                if (!path.includes(next)) {
                                    const { x: x1 = 0, y: y1 = 0 } = model.get(
                                        path[path.length - 1],
                                    );
                                    const { x: x2 = 0, y: y2 = 0 } =
                                        model.get(next);

                                    const dX = x2 - x1;
                                    const dY = y2 - y1;

                                    const nextDist = Math.sqrt(
                                        dX * dX + dY * dY,
                                    );

                                    callCt++;
                                    call(
                                        [
                                            shipUtil.buildDNode('literal', {
                                                path: [...path, next],
                                                distance: distance + nextDist,
                                            }),
                                        ],
                                        explore,
                                        null,
                                        () => {
                                            callCt--;
                                            if (callCt === 0) {
                                                cb();
                                            }
                                        },
                                    );
                                }
                            }
                        }
                    }

                    call(
                        [
                            shipUtil.buildDNode('literal', {
                                path: [from.$ref],
                                distance: 0,
                            }),
                        ],
                        explore,
                        null,
                        () => {},
                    );

                    call([], (cb) => cb(null, bestPath ?? []));
                },
            },
            startLocation: {
                $context: ['hop', 'job', 'shipOrderClaim'],
                $type: 'station',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Start Location',
                $prettyPrint: prettyPlaceholder('start location'),
                $eval: ({}, e, model) =>
                    isKind(e, 'job') || isKind(e, 'hop')
                        ? e.from
                        : shipUtil.branch(e, {
                              claimJob: ({ which }) => model.get(which)?.from,
                              travel: ({ from }) => from,
                          }),
            },
            startLocationIs: {
                $context: ['job', 'shipOrderClaim'],
                $type: 'boolean',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_WITH_ARGS,

                $label: 'Start Location Is...',
                $prettyPrint: ({ start }, { model }) => {
                    const startObj = model.get(start);
                    const root = `start = "${startObj?.name}"`;
                    return root;
                },
                $eval: ({ start }, e, model) => {
                    const actual = isKind(e, 'job')
                        ? e.from
                        : model.get(e.job)?.from;

                    return actual?.$ref === start?.$ref;
                },

                start: { $ref: 'station' },
            },
            stations: {
                $type: 'list station',
                $label: 'Stations',

                order: {
                    $label: 'Sort Order',
                    $default: () => shipUtil.buildDNode('reward'),

                    $expectedTypes: {
                        order: ['number', 'string'],
                    },
                    order: {
                        $label: 'Ordering',
                        $extend: specs.expression,
                    },
                    direction: {
                        $label: 'Direction',
                        $descrim: {
                            ascending: { $label: 'Ascending' },
                            descending: { $label: 'Descending' },
                        },
                    },
                },
                filters: {
                    $label: 'Filters',
                    $array: {
                        $expectedTypes: {
                            elements: 'boolean',
                        },
                        elements: specs.expression,
                    },
                },

                $eval: (
                    { filters, order },
                    shipOrder,
                    model,
                    call,
                    { evalSync, path },
                ) => {
                    return filteredAndOrdered(
                        evalSync,
                        model,
                        { kind: 'stations' },
                        filters,
                        order,
                    );
                },
            },
            subtract: {
                $type: 'number',
                $prettyPrint: leftAssocPrettyPrint(
                    'subtract',
                    '-',
                    'left',
                    'right',
                ),
                $shorthand: [
                    { pivot: 'Subtract' },
                    ' ',
                    // Note the weird order here because of how we tend to say
                    // this in english!
                    { placeholder: 'right' },
                    ' from ',
                    { placeholder: 'left' },
                ],
                $expectedTypes: {
                    left: 'number',
                    right: 'number',
                },
                left: specs.expression,
                right: specs.expression,
            },
            travelDistance: {
                $context: 'job',
                $type: 'number',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'Travel Distance',
                $prettyPrint: prettyPlaceholder('travel distance'),
                $eval: (args, job, model) => {
                    const start = job.from ? model.get(job.from) : undefined;
                    const end = job.to ? model.get(job.to) : undefined;

                    let result;
                    if (start && end) {
                        const dX = end.x - start.x;
                        const dY = end.y - start.y;

                        result = Math.sqrt(dX * dX + dY * dY);
                    } else {
                        result = NaN;
                    }

                    return result;
                },
            },
            travelTo: {
                $context: 'shipOrderTravel',
                $type: 'station',
                $priorityToBeDefault: DPRIORITY_SPECIFIC_NO_ARGS,

                $label: 'To',
                $prettyPrint: prettyPlaceholder('To'),
                $eval: (args, order, model) => {
                    return order.travel.to;
                },
            },
        },
    },
    genericShipOrder: genericShipOrder(specs),
    shipOrder: shipOrdersSpec(specs, (field, type) => ({ [field]: type })),
    shipOrderTemplate: shipOrdersSpec(specs, (field, type, typeName) => ({
        $expectedTypes: {
            [field]: typeName,
        },

        [field]: { $extend: specs.expression },
    })),
}));

function maybeUnpackRef(x) {
    if (x?.$ref) {
        return x.$ref;
    }

    return x;
}

export const accountPreferences = specs.accountPreferences;
export const cargoManifest = specs.cargoManifest;
export const corporationPreferences = specs.corporationPreferences;
export const expression = specs.expression;
export const shipOrder = specs.shipOrder;
export const shipOrderTemplate = specs.shipOrderTemplate;

function isKind(entity, k) {
    entity = shipUtil.unmessy(entity);
    return (entity?.kind ?? []).includes(k);
}

function genericShipOrder(specs, subtype) {
    const result = {
        triggers: {
            $label: 'Automations',

            before: {
                $label: 'Before',
                $optional: true,
                $extend: specs.automations,
            },

            onSuccess: {
                $label: 'On Successful Completion',
                $optional: true,
                $extend: specs.automations,
            },

            onFailure: {
                $label: 'On Failure',
                $optional: true,
                $extend: specs.automations,
            },
        },
    };

    if (subtype) {
        result.$withContexts = {
            triggers: subtype,
        };
    }

    return result;
}

function bound(prefix, root, suffix) {
    return (a, b, c, bounded) =>
        bounded ? `${prefix}${root}${suffix}` : `${root}`;
}

function leftAssocPrettyPrint(opName, opSymbol, leftName, rightName) {
    return (args, { prettyPrint }) => {
        const left = args[leftName];
        const right = args[rightName];

        let { unbounded: leftStr } = prettyPrint(expression, left);
        let { unbounded: rightStr } = prettyPrint(expression, right);

        if (leftStr.includes(' ') || leftStr.includes(opSymbol)) {
            leftStr = `(${leftStr})`;
        }

        if (rightStr.includes(' ')) {
            rightStr = `(${rightStr})`;
        }

        const root = `${leftStr} ${opSymbol} ${rightStr}`;
        return root;
    };
}

function prettyPlaceholder(root) {
    return () => `<${root}>`;
}

export const shipOrders = {
    $permission: '/{{rootId}}/write',
    $array: {
        minLength: 0,
        maxLength: 100,
        elements: shipOrder,
    },
};

export default {
    'ship_*': {
        orders: shipOrders,
    },
};

function cargoManifestSpec(specs, typeFn) {
    return {
        $array: {
            minLength: 0,
            maxLength: 100,
            elements: {
                $tuple: merge(
                    true,
                    [{}, { $int: [0, 10000] }],
                    { $expectedTypes: [], $labels: ['Type', 'Amount'] },
                    typeFn(0, { $ref: 'cargo' }, 'cargo'),
                ),
            },
        },
    };
}

export function expressionType(e) {
    if (!e) {
        return undefined;
    }

    const [type] = shipUtil.dnode(e);
    const typeSpec = shipUtil.extendSpec(expression.$descrim[type]);

    return typeof typeSpec.$type === 'function'
        ? typeSpec.$type(e)
        : typeSpec.$type;
}

function filteredAndOrdered(evalSync, model, matcher, filters, order) {
    const entities = model.getMatches(matcher);

    const matchingIds = [];
    for (const eId of entities) {
        if ((filters ?? []).every((f) => evalSync(f, Vm.context(`/${eId}`)))) {
            matchingIds.push(eId);
        }
    }

    const ascending = shipUtil.dnode(order.direction)[0] === 'ascending';

    const unsorted = matchingIds.map((eId) => {
        const entityDetails = model.get(`/${eId}`);

        return [
            eId,
            entityDetails
                ? evalSync(order.order, Vm.context(`/${eId}`))
                : ascending
                  ? Infinity
                  : -Infinity,
        ];
    });

    unsorted.sort(
        ([, s1], [, s2]) =>
            (s1 < s2 ? -1 : s1 > s2 ? 1 : 0) * (ascending ? 1 : -1),
    );

    return unsorted.map(([id]) => ({ $ref: `/${id}` }));
}

function orderDefaultBuilder(orderType) {
    return (model, { shipId }) => {
        if (shipId) {
            return shipUtil.buildShipDefaultOrderTemplate(
                model,
                shipId,
                orderType,
            );
        }
    };
}

function shipOrdersSpec(specs, typeFn) {
    return {
        $descrim: {
            claimJob: merge(
                true,
                {
                    $label: 'Claim Job',
                    $extend: genericShipOrder(specs, 'shipOrderClaim'),
                    $default: orderDefaultBuilder('claimJob'),

                    $humanize: {
                        en: 'Claim {{which}}',
                    },
                    $icon: 'icons.jsx/claim',

                    skipCorporateAutomation: {
                        $label: 'Skip Corporate Automation',
                        $boolean: true,
                    },
                    which: { $label: 'Job' },
                },
                typeFn('which', { $ref: 'job' }, 'job'),
            ),
            finishJob: merge(
                true,
                {
                    $label: 'Finish Job',
                    $extend: specs.genericShipOrder,
                    $default: orderDefaultBuilder('finishJob'),

                    $humanize: { en: 'Finish {{which}}' },
                    $icon: 'icons.jsx/finishJob',

                    abandonOnFail: {
                        $label: 'Abandon if unsuccessful',
                        $boolean: true,
                        $type: 'boolean',
                    },
                    which: { $label: 'Job' },
                },
                typeFn('which', { $ref: 'job' }, 'job'),
            ),
            install: {
                $label: 'Install Module',
                $extend: specs.genericShipOrder,
                $default: orderDefaultBuilder('install'),

                $humanize: { en: 'Install {{which}}' },
                $icon: 'icons.jsx/install',

                which: { $label: 'Module', $ref: 'module' },
            },
            load: merge(
                true,
                {
                    $label: 'Load Cargo / Passengers',
                    $extend: specs.genericShipOrder,
                    $default: orderDefaultBuilder('load'),

                    $humanize: { en: 'Load cargo' },
                    $icon: 'icons.jsx/loadCargo',
                },
                typeFn(
                    'cargo',
                    cargoManifestSpec(specs, typeFn),
                    'cargoManifest',
                ),
            ),
            travel: merge(
                true,
                {
                    $label: 'Travel',
                    $extend: genericShipOrder(specs, 'shipOrderTravel'),
                    $default: orderDefaultBuilder('travel'),

                    $humanize: { en: 'Travel to {{to}}' },
                    $icon: 'icons.jsx/travel',

                    to: { $label: 'To' },
                },
                typeFn('to', { $ref: 'station' }, 'station'),
            ),
            unload: merge(
                true,
                {
                    $label: 'Unload Cargo / Passengers',
                    $extend: specs.genericShipOrder,
                    $default: orderDefaultBuilder('unload'),

                    $humanize: { en: 'Unload cargo' },
                    $icon: 'icons.jsx/unloadCargo',

                    cargo: specs.cargoManifest,
                },
                typeFn(
                    'cargo',
                    cargoManifestSpec(specs, typeFn),
                    'cargoManifest',
                ),
            ),
            uninstall: {
                $label: 'Uninstall Module',
                $extend: specs.genericShipOrder,
                $default: orderDefaultBuilder('uninstall'),

                $humanize: { en: 'Uninstall {{which}}' },
                $icon: 'icons.jsx/uninstall',

                which: { $label: 'Module', $ref: 'module' },
            },
            wait: {
                $label: 'Wait',
                $extend: specs.genericShipOrder,
                $default: orderDefaultBuilder('wait'),

                $humanize: { en: 'Wait {{duration}}' },
                $icon: 'icons.jsx/wait',

                duration: {
                    $label: 'Duration',
                    $clickDuration: {
                        min: 0,
                        max: 9999,
                        units: 'wall time',
                    },
                },
            },
        },
    };
}
