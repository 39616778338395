import clone from 'clone';
import deepEqual from 'deep-equal';
import react from 'react';
import * as shipUtil from '../../utils/utils.mjs';
import styled from '@emotion/styled';
import useFlexState from '../../hooks/use-flex-state.jsx';
import validate, { ValidationError } from '../../shared/validate.mjs';
import VerticalMainLayout from './VerticalMainLayout.jsx';

import { buildTopLevelUi } from './utils/usernode-editor/build-ui.jsx';
import {
    GameStateContext,
    UsernodeDialogContext,
} from '../../app-contexts.jsx';

import { Button } from './standard.jsx';

const ButtonPanel = styled.div`
    display: flex;

    & > * {
        flex-grow: 1;
    }
`;

const MyButton = styled(Button)`
    border-radius: 0;
    border-style: none;
`;

export default function UsernodeCommitAssembly({
    className,
    debug,
    defaultsCtx,
    expectedType,
    extraComponents,
    flexState: providedFlexState,
    initialValue, // A Pojo.
    lexicalContextStack,
    onCancel,
    onCommit,
    spec,
    vmContext,
}) {
    const { model } = react.useContext(GameStateContext);
    const queryUser = react.useContext(UsernodeDialogContext);
    const fallbackFlexState = useFlexState();

    const flexState = providedFlexState ?? fallbackFlexState;

    const cachedInitialValue = react.useRef();
    if (!cachedInitialValue.current) {
        cachedInitialValue.current = clone(initialValue);
    }

    const [value, setValue] = flexState.useState(
        '/value',
        cachedInitialValue.current,
    );

    const [unodeComponent, unodeProps, massagedData] = buildTopLevelUi(
        model,
        queryUser,
        flexState,
        spec,
        value,
        vmContext,
        expectedType,
        (v) => {
            console.log(
                'UsernodeCommitAssembly onChange',
                JSON.stringify(v, null, 4),
            );
            setValue(v);
        },
        {
            debug,
            defaultsCtx,
            extraComponents,
            lexicalContextStack,
        },
    );

    // If `flexState` belongs to our parent, then `setValue()` here would
    // trigger a 'can't update another component while rendering this one' error
    // were it inline, so get it not-in-line.
    react.useEffect(() => {
        if (!deepEqual(value, massagedData)) {
            setValue(massagedData);
        }
    }, [value, massagedData]);

    function maybeCommit(value) {
        try {
            validate(spec, value);
            console.log('commit', JSON.stringify(value, null, 4));
            onCommit(value);
        } catch (e) {
            console.error(e);
            if (!(e instanceof ValidationError)) {
                shipUtil.unexpectedError(e);
            }

            unodeProps?.revealAllErrors?.(value);
        }
    }

    if (unodeProps?.committer) {
        unodeProps.committer.on('commit', maybeCommit);
        unodeProps.committer.on('cancel', onCancel);
    }

    const footer = !unodeProps?.committer && (
        <ButtonPanel>
            <MyButton size='large' onClick={onCancel}>
                Cancel
            </MyButton>
            <MyButton
                size='large'
                variant='primary'
                onClick={() => maybeCommit(value)}
            >
                Commit
            </MyButton>
        </ButtonPanel>
    );

    return (
        <VerticalMainLayout className={className} {...{ footer }}>
            {unodeComponent}
        </VerticalMainLayout>
    );
}
