import clone from 'clone';
import * as shipUtil from '../utils/utils.mjs';

import { expression } from '../specs.mjs';
import { debugString, dnode, top } from '../utils/utils.mjs';

export class ClientProblem extends Error {
    constructor(msg, cause) {
        super(msg);

        if (cause) {
            this.cause = cause;
        }
    }
}

export default class Vm {
    constructor(model, { deps = { console } } = {}, opts = {}) {
        this.deps = deps;
        this.model = opts.noCopy ? model : model.copy();
        this.threads = [];
    }

    // Favor an entity path like `/job_abc` if the entity exists in the model.
    // This maintains the `path` for use in $eval.
    static context(entity) {
        const path = typeof entity === 'string' ? entity : undefined;
        let result = {};

        for (const [name, fn] of Object.entries(expression.$descrim)) {
            if (fn.$eval) {
                result[name] = (args, frame) =>
                    fn.$eval(
                        args,
                        (() => {
                            let evalCtx;
                            if (typeof entity === 'string') {
                                evalCtx = frame.model.get(entity);
                                if (!evalCtx) {
                                    throw new Error(
                                        'No entity ' + entity + '???',
                                    );
                                }
                            } else {
                                evalCtx = entity;
                            }

                            return evalCtx;
                        })(),
                        frame.model,
                        frame.doCall.bind(frame),
                        {
                            evalSync: frame.evalSync.bind(frame),
                            path,
                            get dynamicContextStack() {
                                return frame.getDynamicContextStack();
                            },
                        },
                    );
            }
        }

        return result;
    }

    withSharedResources(m) {
        return new Vm(m);
    }

    copy() {
        return new Vm(this.model);
    }

    crank() {
        for (const t of this.threads) {
            t.crank();
        }

        this.threads = this.threads.filter((t) => !t.finished);

        return this.threads.length > 0;
    }

    destroy() {
        this.destroy = true;
        this.deps = null;
        this.model = null;
        this.threads = null;
    }

    async evaluate(exp, ctx = {}) {
        const threadObj = thread.call(this, exp, ctx, this.model);
        this.threads.push(threadObj);
        return await threadObj.resultPr;
    }

    evaluateSync(exp, ctx = {}, opts = {}) {
        const t = thread.call(this, exp, ctx, this.model);

        if (opts.debug) {
            this.deps.console.log(t.toString());
        }

        while (!t.finished) {
            t.crank();

            if (opts.debug) {
                this.deps.console.log(t.toString());
            }
        }

        return t.result;
    }

    static tempVm(model, fn) {
        const vm = new Vm(model, undefined, { noCopy: true });
        const result = fn(vm);
        vm.destroy();

        return result;
    }

    toString() {
        let result = '### VM ###\n\n';
        for (const t of this.threads) {
            result += t.toString();
        }

        return result;
    }
}

function thread(exp, ctx, model) {
    const vm = this;
    const name = () => debugString(exp);

    let resolve, reject;
    const pr = new Promise((rs, rj) => {
        resolve = rs;
        reject = rj;
    });

    const stack = [];
    function threadCrank() {
        try {
            top(stack)?.crank();
        } catch (e) {
            if (!(e instanceof ClientProblem)) {
                console.error(this?.toString());
            }

            throw e;
        }
    }

    function frame(exp, ctxs, resolve) {
        const name = () => debugString(exp);

        return {
            args: [],
            crank() {
                if (this.ops.length === 0) {
                    stack.pop();
                    resolve(top(this.args));
                } else {
                    this.ops.shift()(this);
                }
            },
            doCall(exps, fn, newCtx, cb = (v) => top(stack).args.push(v)) {
                top(stack).ops.push(() => {
                    const resolvedExps = [];

                    const newCtxs = buildNewCtxs(ctxs, newCtx);

                    // By time this runs here at the bottom of the stack we're
                    // building, `resolvedExps` will be filled.
                    stack.push(
                        frame(
                            (frame) => {
                                fn(
                                    (err, result) => {
                                        if (err) {
                                            if (err instanceof ClientProblem) {
                                                throw new ClientProblem(
                                                    err.message,
                                                    err,
                                                );
                                            }

                                            throw new Error(err.message);
                                        }

                                        frame.args.push(result);
                                    },
                                    ...resolvedExps,
                                );
                            },
                            newCtxs,
                            (v) => cb(v),
                        ),
                    );

                    for (const e of exps) {
                        stack.push(
                            frame(e, newCtxs, (v) => resolvedExps.unshift(v)),
                        );
                    }
                });
            },
            evalSync(exp, newCtx) {
                let result;
                let done;

                const newCtxs = buildNewCtxs(ctxs, newCtx);

                stack.push(
                    frame(exp, newCtxs, (v) => {
                        done = true;
                        result = v;
                    }),
                );

                while (!done) {
                    threadCrank();
                }

                return result;
            },
            getDynamicContextStack() {
                return ctxs;
            },
            model,
            ops: [
                typeof exp === 'function'
                    ? exp
                    : evalOp.call(vm, exp, shipUtil.top(ctxs)[1]),
            ],
            toString() {
                let result = '  --- Frame ' + name() + ' ---\n';
                for (const arg of this.args) {
                    result += '      ' + debugString(arg) + '\n';
                }

                return result;
            },
        };
    }

    let result;
    stack.push(
        frame(
            exp,
            shipUtil.coerceToArray(ctx).map((c) => [undefined, c]),
            (r) => {
                result = r;
                resolve(r);
            },
        ),
    );

    return {
        crank: threadCrank,
        resultPr: pr,
        get result() {
            return result;
        },
        get finished() {
            return stack.length === 0;
        },
        toString() {
            let result = `=== Thread ${name()} ===\n\n`;

            for (const frame of stack) {
                result += frame.toString() + '\n';
            }

            return result;
        },
    };
}

function buildNewCtxs(curCtxs, newCtx) {
    const newCtxs = [...curCtxs];

    if (typeof newCtx === 'number') {
        for (let i = 0; i < newCtx * -1; i++) {
            if (newCtxs.length === 0) {
                throw new ClientProblem(
                    'Requested return to context ' +
                        'stack position ' +
                        newCtx * -1 +
                        ' but context stack is only ' +
                        curCtxs.length +
                        ' deep.',
                );
            }

            newCtxs.pop();
        }
    } else if (newCtx) {
        const [newCtxName, newCtxFns] = Array.isArray(newCtx)
            ? newCtx
            : [undefined, newCtx];

        newCtxs.push([newCtxName, newCtxFns]);
    }

    return newCtxs;
}

function eqOp(v1Exp, v2Exp) {
    const result = (frame) =>
        frame.doCall([v1Exp, v2Exp], (cb, v1, v2) => {
            if (Array.isArray(v1) && Array.isArray(v2)) {
                if (v1.length !== v2.length) {
                    cb(null, false);
                } else {
                    function checkIndex(cb, i) {
                        if (i >= v1.length) {
                            cb(null, [i, true]);
                        } else {
                            frame.doCall(
                                [eqOp(v1[i], v2[i])],
                                (err, result) => cb(null, [i, result]),
                                undefined,
                                () => {},
                            );
                        }
                    }

                    function callNext([i, result]) {
                        i++;
                        if (result && i < v1.length) {
                            frame.doCall([i], checkIndex, undefined, callNext);
                        } else {
                            cb(null, result);
                        }
                    }

                    frame.doCall([0], checkIndex, undefined, callNext);
                }
            } else {
                cb(null, terminalsEqual(v1, v2));
            }
        });

    result.toString = () => `eqOp ${debugString(v1Exp)} ${debugString(v2Exp)}`;

    return result;
}

function evalOp(exp, ctx = {}) {
    if (Array.isArray(exp)) {
        return (frame) => {
            frame.doCall(exp, (cb, ...evaledArray) => cb(null, evaledArray));
        };
    }

    if (typeof exp === 'object' && !exp?.$ref) {
        const [op, args] = dnode(exp);

        if (!fns[op] && !ctx[op]) {
            throw new Error('Unknown function: ' + debugString(exp));
        }

        return (frame) => {
            const result = (fns[op] ?? ctx[op])(args, frame);

            if (result !== undefined) {
                frame.args.push(result);
            }

            return result;
        };
    }

    return (frame) => frame.args.push(exp);
}

function terminalsEqual(t1, t2) {
    if (t1?.$ref) {
        return t2?.$ref === t1.$ref;
    }

    return t1 === t2;
}

var fns = {
    divide: (args, frame) => {
        frame.doCall([args.numerator, args.denominator], (cb, n, d) =>
            cb(null, n / d),
        );
    },
    eq: (args, frame) => {
        frame.doCall([eqOp(args.left, args.right)], (cb, x) => cb(null, x));
    },
    literal: (args, frame) => args,
    neq: (args, frame) => {
        frame.doCall([eqOp(args.left, args.right)], (cb, x) => cb(null, !x));
    },
    subtract: (args, frame) => {
        frame.doCall([args.left, args.right], (cb, left, right) =>
            cb(null, left - right),
        );
    },
    with: (args, frame) => {
        frame.doCall([args.eval], (cb, r) => cb(null, r), args.ctx);
    },
};
