import * as appContexts from '../../app-contexts.jsx';
import DebugPopover from './DebugPopover.jsx';
import Jobs from '../pages/Jobs.jsx';
import Modal from 'react-modal';
import react from 'react';
import styled from '@emotion/styled';
import * as tasks from '../../hooks/task-initiators.jsx';
import useFlexState from '../../hooks/use-flex-state.jsx';
import UsernodeCommitAssembly from './UsernodeCommitAssembly.jsx';
import VerticalMainLayout from './VerticalMainLayout.jsx';

import { Button } from './standard.jsx';
import { ClassNames } from '@emotion/react';
import { Else, If, Then } from 'react-if';

const ModalDiv = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 77%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgb(0, 0, 0, 0.5);

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
`;

const MyUsernodeCommitAssembly = styled(UsernodeCommitAssembly)`
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
`;

const overlayCss = `
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background-color: rgb(0, 0, 0, .2);
	backdrop-filter: blur(2px);
`;

const Title = styled(DebugPopover)`
    padding: 5px;
`;

export default function SelectEntityModal({
    context,
    expectedType,
    info,
    initialValue,
    isOpen,
    lexicalContextStack,
    onRequestClose,
    onSelect,
    spec,
    title,
}) {
    const { rootId } = react.useContext(appContexts.DependenciesContext);

    const origOnSelect = onSelect;
    onSelect = (v) => {
        console.log('SelectEntityModal.jsx', JSON.stringify(v, null, 4));

        return origOnSelect(v);
    };

    const debugText = `expectedType: ${expectedType}, context: ${context}`;

    // The way react-modal works, even if we keep the modal in the tree and just
    // set isOpen=false, the component is removed from the DOM and state is
    // lost, which isn't desirable when we have nested modals (when you return
    // to the parent modal it should maintain state). So we provide the modal
    // this generic-state-keeping location to preserve state even when ModalDiv
    // below becomes hidden.
    const flexState = useFlexState();

    const taskInitiator = tasks.useDialogInitiator();

    return (
        <ClassNames>
            {({ css }) => (
                <ModalDiv
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    overlayClassName={css`
                        ${overlayCss}
                    `}
                    parentSelector={() => document.querySelector(`#${rootId}`)}
                >
                    <If condition={title}>
                        <Then>
                            <Title popover={debugText}>{title}</Title>
                        </Then>
                        <Else>
                            <div />
                        </Else>
                    </If>
                    <If condition={info}>
                        <Then>
                            <VerticalMainLayout
                                footer={
                                    <Button
                                        size='large'
                                        onClick={onRequestClose}
                                    >
                                        Back
                                    </Button>
                                }
                            >
                                {info}
                            </VerticalMainLayout>
                        </Then>
                        <Else>
                            {spec?.$ref === 'job' ? (
                                <Jobs
                                    map={false}
                                    onSelect={onSelect}
                                    taskInitiator={taskInitiator}
                                    whiteboard={flexState.buildWhiteboard(
                                        '/wb',
                                    )}
                                />
                            ) : (
                                <MyUsernodeCommitAssembly
                                    {...{
                                        expectedType,
                                        flexState,
                                        initialValue,
                                        lexicalContextStack,
                                        onCancel: onRequestClose,
                                        onCommit: onSelect,
                                        spec,
                                        vmContext: context,
                                    }}
                                />
                            )}
                        </Else>
                    </If>
                </ModalDiv>
            )}
        </ClassNames>
    );
}
