import * as appContexts from '../app-contexts.jsx';
import CardNavigator from '../components/fragments/card-navigator';
import corporationPreferencesPage from './pages/corporation-preferences-page.jsx';
import digestPage from './pages/digest-page.jsx';
import LoadingOverlay from '../components/fragments/LoadingOverlay.jsx';
import Modal from 'react-modal';
import notificationsPreferencesPage from './pages/notifications-preferences-page.jsx';
import OverlayLayout from '../components/fragments/OverlayLayout.jsx';
import react from 'react';
import * as reactRouter from 'react-router-dom';
import SelectEntityModal from '../components/fragments/SelectEntityModal.jsx';
import * as shipUtil from '../utils/utils.mjs';
import Sidebar from '../components/fragments/Sidebar.jsx';
import useNavigate from '../hooks/use-navigate.jsx';
import useStateMachine from '../hooks/use-state-machine.jsx';
import useWhiteboard from '../hooks/use-whiteboard.jsx';
import mapLayout from './map-layout/map-layout.jsx';

import { Fragment, memo, useMemo, useRef } from 'react';
import { generateId } from '../shared/model.mjs';
import { When } from 'react-if';
import { Ui } from './utils/base-layout-components.jsx';

// Base layout provides only the various overlay things--the sidebar, dialogs,
// and full page messages like loading displays and server errors.
export default {
    Component: memo(function BaseLayoutComponent() {
        const { model, resync } = react.useContext(
            appContexts.GameStateContext,
        );

        const serverPaused =
            // Make sure we have SOME data from the server...
            model?.get('/config') &&
            // Now check our runSteps
            (model?.get('/config/runSteps') ?? 0) === 0;

        const [resyncDisplayedAt, setResyncDisplayedAt] = react.useState();
        const [openTimeout, setOpenTimeout] = react.useState();

        const [showResyncOverlay, changeResyncState] = useStateMachine(
            [resync],
            resync ? 'visible' : 'hidden',
            {
                hidden: {
                    getValue() {
                        return false;
                    },
                    onChange([resync]) {
                        if (resync) {
                            changeResyncState('visible');
                        }
                    },
                },
                visible: {
                    getValue() {
                        return true;
                    },
                    onEnter() {
                        setResyncDisplayedAt(Date.now());
                    },
                    onExit() {
                        setResyncDisplayedAt(null);
                    },
                    onChange([resync]) {
                        if (!resync) {
                            const displayedForMs =
                                Date.now() - resyncDisplayedAt;
                            const remainingMs =
                                displayedForMs > 2000
                                    ? 0
                                    : displayedForMs < 0
                                      ? 2000
                                      : 2000 - displayedForMs;

                            setOpenTimeout(
                                setTimeout(
                                    () => changeResyncState('hidden'),
                                    remainingMs,
                                ),
                            );
                        } else {
                            clearTimeout(openTimeout);
                            setOpenTimeout(null);
                        }
                    },
                },
            },
        );

        const usernodeDialogStackRef = react.useRef([]);
        const usernodeDialogStack = usernodeDialogStackRef.current;

        const whiteboard = useWhiteboard();
        const navigate = useNavigate(whiteboard, ['corp']);
        const location = reactRouter.useLocation();

        const pushUsernodeDialog = react.useMemo(() => {
            return (props) => {
                if (location.state?.dialog) {
                    while (
                        usernodeDialogStack.length > 0 &&
                        usernodeDialogStack[usernodeDialogStack.length - 1]
                            .key !== location.state?.dialog
                    ) {
                        usernodeDialogStack.pop();
                    }
                }

                usernodeDialogStack.push(props);

                navigate(null, {
                    state: {
                        dialog: props.key,
                    },
                });
            };
        }, [location, navigate, usernodeDialogStack]);

        const popUsernodeDialog = react.useMemo(() => {
            return () => {
                usernodeDialogStack.pop();
                navigate(-1);
            };
        }, [navigate]);

        const usernodeDialogFn = react.useMemo(() => {
            return selectEntityModalCtxValue(
                pushUsernodeDialog,
                popUsernodeDialog,
            );
        }, [pushUsernodeDialog, popUsernodeDialog]);

        const titleRef = useRef();
        const topRightRef = useRef();

        const [sidebarDisplayed, setSidebarDisplayed] = react.useState();
        function onMenuClick() {
            setSidebarDisplayed(true);
        }

        const context = useMemo(
            () => ({
                titleRef,
                topRightRef,
                showSidebar: () => setSidebarDisplayed(true),
                whiteboard,
            }),
            [setSidebarDisplayed, whiteboard],
        );

        const { ui } = react.useContext(appContexts.DependenciesContext);
        let fUi = ui;
        if (!fUi) {
            fUi = (
                <Fragment>
                    <CardNavigator
                        onMenuClick={onMenuClick}
                        titleRef={titleRef}
                        topRightRef={topRightRef}
                    />
                    <reactRouter.Outlet context={context} />
                </Fragment>
            );
        }

        react.useLayoutEffect(() => {
            Modal.setAppElement(`#aegis-main-content`);
        }, []);

        const base = (
            <appContexts.UsernodeDialogContext.Provider
                value={usernodeDialogFn}
            >
                <Ui id='aegis-main-content'>{fUi}</Ui>
                <When condition={sidebarDisplayed}>
                    <Sidebar
                        onRequestClose={(navigateTo) => {
                            setSidebarDisplayed(false);

                            if (navigateTo) {
                                navigate(navigateTo);
                            }
                        }}
                    />
                </When>
                {
                    // All dialogs need to stay in the tree regardless
                    // of whether or not they are shown via isOpen so
                    // that they maintain their state.
                    usernodeDialogStack.map((props) => (
                        <SelectEntityModal
                            key={props?.key}
                            isOpen={props?.key === location.state?.dialog}
                            {...props}
                            debug={props?.key}
                        />
                    ))
                }
            </appContexts.UsernodeDialogContext.Provider>
        );

        return serverPaused ? (
            <OverlayLayout css={{ height: '100dvh' }}>
                {base}
                <LoadingOverlay>Server is paused.</LoadingOverlay>
            </OverlayLayout>
        ) : showResyncOverlay ? (
            <OverlayLayout css={{ height: '100dvh' }}>
                {base}
                <LoadingOverlay>Resyncing...</LoadingOverlay>
            </OverlayLayout>
        ) : (
            base
        );
    }),
    ErrorBoundary: () => {
        const e = reactRouter.useRouteError();
        console.error(e);
        return <p>{e.message}</p>;
    },
    children: [
        corporationPreferencesPage,
        digestPage,
        mapLayout,
        notificationsPreferencesPage,
    ],
};

function selectEntityModalCtxValue(pushUsernodeDialog, popUsernodeDialog) {
    const result = (
        spec,
        initialValue,
        { context, expectedType, lexicalContextStack, title } = {},
    ) => {
        console.log('base-layout lexicalContextStack', lexicalContextStack);

        let resolve;
        const pr = new Promise((rs) => {
            resolve = rs;
        });

        function onDone(result) {
            popUsernodeDialog();
            resolve(result);
        }

        pushUsernodeDialog({
            context,
            expectedType,
            initialValue,
            key: generateId('dlg'),
            lexicalContextStack,
            spec,
            title,
            onRequestClose: () => onDone(undefined),
            onSelect: (v) => {
                console.log('base-layout onSelect', JSON.stringify(v, null, 4));
                onDone(v);
            },
        });

        return pr;
    };

    result.displayInfo = (children, { title } = {}) => {
        const [pr, resolve] = shipUtil.promise();

        function onDone(result) {
            popUsernodeDialog();
            resolve(result);
        }

        pushUsernodeDialog({
            info: children,
            key: generateId('dlg'),
            onRequestClose: () => onDone(undefined),
            title,
        });

        return pr;
    };

    return result;
}
