import clone from 'clone';
import * as shipUtil from '../utils/utils.mjs';

function buildResult(walker, spec, value, ctx) {
    return (
        walker?.buildResult ??
        ((s, v, { parentSpec, subwalk }) => {
            if (parentSpec?.$descrim) {
                const [dKey] = shipUtil.dnode(v, null);

                if (dKey) {
                    return shipUtil.buildDNode(dKey, subwalk);
                }
            }

            return noUndef(subwalk);
        })
    )(spec, value, ctx);
}

function noUndef(v) {
    if (Array.isArray(v) || typeof v !== 'object' || v === null) {
        return v;
    }

    const result = Object.fromEntries(
        Object.entries(v).filter(([k, v]) => v !== undefined),
    );

    return Object.keys(result).length === 0 ? undefined : result;
}

const justTheValue = (w, s, value) => value;

let indent = '';
const annotations = {
    $array: (walker, spec, value, ctx) =>
        value?.map?.((el) => walkSpec(walker, spec.elements, el, ctx)),
    $boolean: justTheValue,
    $clickDuration: justTheValue,
    $descrim: (walker, spec, value, ctx) => {
        const [descrim, subvalue] = shipUtil.dnode(value, null);

        if (descrim && !spec[descrim]) {
            throw new Error(
                'Value of type ' +
                    descrim +
                    " doesn't meet spec. Value: " +
                    shipUtil.debugString(value) +
                    ', spec: ' +
                    shipUtil.debugString(spec),
            );
        }

        if (descrim) {
            return walkSpec(walker, spec[descrim] ?? {}, subvalue, {
                ...ctx,
                discrimKey: descrim,
            });
        }

        return undefined;
    },
    $int: justTheValue,
    $ref: justTheValue,
    $string: justTheValue,
    $tuple: (walker, spec, value, ctx) =>
        value?.map?.((el, i) => walkSpec(walker, spec[i], el, ctx)),
};

/**
 * Some counter-intuitive (but correct) things to keep in mind:
 *
 * During a `buildResult` hook, the value-parameter will be (as for all other
 * hooks!) the original given value of the node. In many cases you will instead
 * want the returned subwalk, available in the options-parameter as `subwalk`.
 *
 * Regarding $descrim nodes: When the walker hits a specified dnode, it calls
 * `before`, `after`, and `buildResult` as with ANY OTHER NODE--i.e., passing
 * the spec associated with the $descrim and the complete dnode value -without-
 * trying to make sense of the dnode. As part of the -subwalk-, the dnode is
 * parsed, the correct discriminated subspec decided upon, and then that subspec
 * and the dnode's value (without differentiator) walked appropriately. This
 * subwalk can be recognized because `discrimKey` will be passed into the
 * walker's options. The default buildResult() of a $descrim will simply
 * construct a new dnode with the existing discriminator type and a value as
 * returned from the subwalk. Thus, it is not possible from within the subwalk
 * to "change" the discrim type. This must be done at the $descrim node before
 * the dnode value is parsed.
 */
export default function walkSpec(walker, spec, value, ctx = {}) {
    indent = indent + '    ';

    if (typeof spec !== 'object' || spec === null) {
        throw new Error('Invalid spec: ' + shipUtil.debugString(spec));
    }

    spec = shipUtil.extendSpec(spec);

    const walkersAnnotations = new Set([...Object.keys(walker)]);
    for (const a of Object.keys(annotations)) {
        walkersAnnotations.delete(a);
        if (!(a in walker)) {
            throw new Error('Walker missing entry for ' + a);
        }
    }

    walkersAnnotations.delete('$struct');
    if (!walker.$struct) {
        throw new Error('Walker missing entry for $struct.');
    }

    walkersAnnotations.delete('$zero');
    if (!walker.$zero) {
        throw new Error('Walker missing entry for $zero.');
    }

    if (walkersAnnotations.size > 0) {
        throw new Error('Walker has extra entries: ' + [...walkersAnnotations]);
    }

    let someBuild;
    let result = ctx.result;
    for (const [k, v] of Object.entries(annotations)) {
        if (k in spec) {
            const before = walker?.[k]?.before?.(spec?.[k], value, {
                ...ctx,
                before: ctx.before,
                curResult: result,
                parentSpec: spec,
            });

            const subwalk = annotations[k](walker, spec?.[k], value, {
                ...ctx,
                before,
                curResult: result,
                parentSpec: spec,
            });

            const after = walker?.[k]?.after?.(spec?.[k], value, {
                ...ctx,
                before,
                curResult: result,
                parentSpec: spec,
                subwalk,
            });

            someBuild = true;

            result = buildResult(walker?.[k], spec?.[k], value, {
                ...ctx,
                after,
                annotation: k,
                before,
                curResult: result,
                parentSpec: spec,
                subwalk,
            });
        }
    }

    let structy = false;
    const relevantCopy = {};
    const fieldNames = Object.keys(spec).filter(([k]) => !k.startsWith('$'));
    for (const k of fieldNames) {
        structy = true;
        relevantCopy[k] = value?.[k];
    }

    if (structy) {
        const before = walker?.$struct?.before?.(spec, relevantCopy, {
            ...ctx,
            before: ctx.before,
            curResult: result,
        });

        const subwalk = Object.fromEntries(
            fieldNames.map((k) => [
                k,
                walkSpec(walker, spec[k], value?.[k], ctx),
            ]),
        );

        const after = walker?.$struct?.after?.(spec, relevantCopy, {
            ...ctx,
            before,
            curResult: result,
            subwalk,
        });

        someBuild = true;
        result = buildResult(walker.$struct, spec, value, {
            ...ctx,
            after,
            annotation: '$struct',
            before,
            curResult: result,
            subwalk,
        });
    }

    if (!someBuild) {
        const before = walker?.$zero?.before?.(spec, value, {
            ...ctx,
            before: ctx.before,
            curResult: result,
        });

        const after = walker?.$zero?.after?.(spec, value, {
            ...ctx,
            before,
            curResult: result,
            subwalk: value,
        });

        result = buildResult(walker.$zero, spec, value, {
            ...ctx,
            after,
            annotation: '$zero',
            before,
            curResult: result,
            subwalk: value,
        });
    }

    indent = indent.substring(4);

    return result;
}
