import ArrayNode from './ArrayNode.jsx';
import Observable from '../../../../utils/Observable.mjs';
import react from 'react';
import * as shipUtil from '../../../../utils/utils.mjs';
import ShipErrorBoundary from '../../ShipErrorBoundary.jsx';

import { buildUi } from './build-ui.jsx';

export default function buildArrayNode(
    spec,
    value,
    expectedType,
    createElement,
    onChange,
    ctx,
) {
    const elementComponents = [];
    const elementProperties = [];

    if (!Array.isArray(value)) {
        value = [];
    } else {
        value = [...value];
    }

    const properties = {
        structured: true,
        elements: elementProperties,
    };

    for (const [i, el] of Object.entries(value)) {
        try {
            const [
                subspec,
                { context: subContext, expectedType: subExpectedType },
            ] = shipUtil.effectiveSpec(
                ctx.context,
                expectedType,
                { $array: spec },
                i,
            );

            let [c, p, d] = buildUi(
                subspec,
                el,
                subExpectedType,
                (v, opts) =>
                    onChange(
                        [...value.slice(0, i), v, ...value.slice(i + 1)],
                        opts,
                    ),
                {
                    ...ctx,

                    context: subContext,
                    displayContext: ctx.displayContext.child(i),
                    displayHierarchy: [...ctx.displayHierarchy, 'ArrayNode'],
                    parentTab: `${i}`,
                    path: `${ctx.path}/${i}`,
                    touched: shipUtil.removeDictLayer(ctx.touched, `${i}`),
                    validationErrors: shipUtil.removeDictLayer(
                        ctx.validationErrors,
                        `${i}`,
                    ),
                },
            );

            value[i] = d;

            if (p?.committer) {
                if (!properties.committer) {
                    properties.committer = new Observable();
                }

                p.committer.on('cancel', () =>
                    properties.committer.fire('cancel'),
                );

                p.committer.on('commit', (v) => {
                    properties.committer.fire('commit', [
                        ...value.slice(0, i),
                        v,
                        ...value.slice(i + 1),
                    ]);
                });
            }

            elementComponents.push(
                <react.Fragment key={p.key ?? i}>{c}</react.Fragment>,
            );
            elementProperties.push(p);
        } catch (e) {
            console.error(e);

            elementComponents.push(
                <ShipErrorBoundary key={i} force={e.message} json={el} />,
            );
            elementProperties.push({});
        }
    }

    return [
        // eslint-disable-next-line react/jsx-key
        <ArrayNode
            createElement={createElement}
            deleteElement={(i) =>
                onChange([...value.slice(0, i), ...value.slice(1 + 1)])
            }
            data={value}
            spec={spec}
            ctx={ctx}
        >
            {elementComponents}
        </ArrayNode>,
        properties,
        value,
    ];
}
